import React from "react";
import "./toggle.css";

export class Toggle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value ?? false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        value: this.props.value,
      });
    }
  }

  handleClick(event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.props.onChange) if (!this.props.onChange()) return false;

    this.setState({
      value: !this.state.value,
    });
    return true;
  }

  handleSwitch(event) {
    return true;
  }

  render() {
    return (
      <label
        className="toggleSwitch"
        onClick={(event) => this.handleClick(event)}
      >
        <input
          type="checkbox"
          checked={this.state.value}
          onChange={(event) => this.handleSwitch(event)}
        />
        <span className="slider"></span>
      </label>
    );
  }
}
