import React from "react";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import "@polymer/paper-button/paper-button.js";
import "@polymer/paper-dialog/paper-dialog.js";
import "@polymer/paper-dialog-scrollable/paper-dialog-scrollable.js";
import _, { add } from "lodash";
import debounce from "lodash.debounce";
import "./rules.css";
import { InplaceEdit } from "./components/inplaceEdit";

const PREFIX_NORMAL = "";
const PREFIX_VIRT = "_virt_";
const PREFIX_PREV = "_prev_";
const PREFIX_VAR = "_var";
const PREFIX_VAL = "_val";
const PREFIX_FUNC = "_func";

const LIST_PREFIX_ALL = [
  PREFIX_VIRT,
  PREFIX_PREV,
  PREFIX_VAR,
  PREFIX_VAL,
  PREFIX_FUNC,
];

const COND_PREFIX_LIST = [
  { key: PREFIX_NORMAL, value: "Device" },
  { key: PREFIX_VIRT, value: "Virtual" },
  { key: PREFIX_PREV, value: "Previous" },
  { key: PREFIX_VAR, value: "Variable" },
  { key: PREFIX_VAL, value: "Value" },
];

const OPER_PREFIX_LIST = [
  { key: PREFIX_NORMAL, value: "Device" },
  { key: PREFIX_VIRT, value: "Virtual" },
  { key: PREFIX_VAR, value: "Variable" },
  { key: PREFIX_FUNC, value: "Function" },
];

const IF_COND_MODE_EVERYTIME = "EVERYTIME";
const IF_COND_MODE_FIRSTCHANGED = "FIRSTCHANGED";
const IF_COND_MODE_ANYCHANGED = "ANYCHANGED";
const IF_COND_MODE_ALLCHANGED = "ALLCHANGED";

const IF_COND_MODE_LIST = [
  { key: IF_COND_MODE_EVERYTIME, value: "Everytime" },
  { key: IF_COND_MODE_FIRSTCHANGED, value: "First changed" },
  { key: IF_COND_MODE_ANYCHANGED, value: "Any changed" },
  { key: IF_COND_MODE_ALLCHANGED, value: "All changed" },
];

const OPERATOR_LIST = ["<=", ">=", ">", "<", "!=", "="];

export default class CardRulesSubdialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rules: [],
    };
    this.rulesCurrent = [];
    this.debouncedRestUpdateRule = debounce(
      (rule) => this.restUpdateRule(rule),
      1000
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!nextProps.card) {
      return false;
    }
    if (!nextProps.allEndpoints) {
      return false;
    }

    if (
      !this.props.card ||
      !nextProps.card ||
      JSON.stringify(this.props.card.endpointNames) !==
        JSON.stringify(nextProps.card.endpointNames)
    ) {
      this.rulesCurrent = [];
    }
    return true;
  }

  componentDidMount() {
    if (this.props.card) {
      var nextEndpoints = this.props.card.endpointNames
        .map((endpointName) => {
          return this.props.allEndpoints[endpointName];
        })
        .filter((endpoint) => endpoint);
      this.endpoints = nextEndpoints;
      this.fetchRules(this.endpoints);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.card) {
      var nextEndpoints = this.props.card.endpointNames
        .map((endpointName) => {
          return this.props.allEndpoints[endpointName];
        })
        .filter((endpoint) => endpoint);
      if (
        !prevProps.card ||
        JSON.stringify(this.props.card.endpointNames) !==
          JSON.stringify(prevProps.card.endpointNames)
      ) {
        this.endpoints = nextEndpoints;
        this.fetchRules(this.endpoints);
      }
    }
  }

  fetchRules(endpoints) {
    console.log("fetchRules:", endpoints);
    this.rules = [];
    for (let endpointKey in endpoints) {
      let endpoint = endpoints[endpointKey];
      fetch(
        window.serverAddr +
          "/api/endpoint/" +
          endpoint.endpointAddr +
          "/rules/",
        {
          mode: "cors",
          credentials: "include",
        }
      )
        .then((res) => res.json())
        .then((result) => {
          this.rules = this.rules.concat(result);
          this.rules = this.rules.sort((ruleJsonA, ruleJsonB) =>
            ruleJsonA.endpointAddr < ruleJsonB.endpointAddr ||
            (ruleJsonA.endpointAddr === ruleJsonB.endpointAddr &&
              ruleJsonA.ruleId < ruleJsonB.ruleId)
              ? -1
              : ruleJsonA.endpointAddr === ruleJsonB.endpointAddr &&
                ruleJsonA.ruleId === ruleJsonB.ruleId
              ? 0
              : 1
          );

          this.rulesCurrent = JSON.parse(JSON.stringify(this.rules));
          this.setState({
            rules: this.rules,
          });
        });
    }
  }

  restUpdateRule(rule) {
    console.log("restUpdateRule:", rule);
    return fetch(window.serverAddr + "/api/get/ruleToStr", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": window.token,
      },
      body: JSON.stringify({
        ruleJson: rule,
      }),
      mode: "cors",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(
          "updating rule:",
          result.status === 0 ? result.value.str : result
        );
        if (result.status === 0) {
          fetch(
            window.serverAddr +
              "/api/endpoint/" +
              rule.endpointAddr +
              "/rule/update",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": window.token,
              },
              body: JSON.stringify({
                ruleId: rule.ruleId,
                ruleStr: result.value.str,
              }),
              mode: "cors",
              credentials: "include",
            }
          )
            .then((res) => res.json())
            .then((result) => {
              //alert(result.status === 0 ? result.value.str : result);
              console.log(
                "updated rule:",
                result.status === 0 ? result.value.str : result
              );
            });
        }
      });
  }

  getEndpointName(endpoint) {
    var name = endpoint.mapperName;
    if (!name) return endpoint.name;

    if (endpoint.location && endpoint.location.length)
      name = name + "(" + endpoint.location + ")";
    else name = name + "(" + endpoint.name + ")";
    return name;
  }

  getCardEndpoints() {
    return this.props.card.endpointNames.map((endpointAddr) => {
      let endpoint = this.props.allEndpoints[endpointAddr];
      return {
        key: endpoint.endpointAddr,
        value: this.getEndpointName(endpoint),
      };
    });
  }

  splitAddressAndPrefix(address) {
    for (let it in LIST_PREFIX_ALL) {
      let prefix = LIST_PREFIX_ALL[it];
      if (address.startsWith(prefix)) {
        return { prefix: prefix, address: address.substring(prefix.length) };
      }
    }

    return { prefix: PREFIX_NORMAL, address: address };
  }

  filterByPrefix(prefix, requireCapability = null) {
    if (
      prefix === PREFIX_NORMAL ||
      prefix === PREFIX_PREV ||
      prefix === PREFIX_VIRT
    ) {
      var withPrefix = false;
      if (prefix === PREFIX_PREV) withPrefix = true;
      return Object.values(this.props.allEndpoints)
        .filter(
          (endpoint) =>
            withPrefix === true || endpoint.endpointAddr.startsWith(prefix)
        )
        .filter(
          (endpoint) =>
            requireCapability === null ||
            endpoint.capabilityTypes.includes(requireCapability)
        )
        .map((endpoint) => {
          return {
            key: endpoint.endpointAddr.substring(
              withPrefix === true ? 0 : prefix.length
            ),
            value: this.getEndpointName(endpoint),
          };
        });
    } else if (prefix === PREFIX_VAR) {
      return false;
    } else if (prefix === PREFIX_VAL) {
      return false;
    } else if (prefix === PREFIX_FUNC) {
      return false;
    }
  }

  filterRawAttrByEndpoint(endpointAddrPrefix, endpointAddr, capabilityFilter) {
    if (endpointAddrPrefix === PREFIX_VIRT) {
      endpointAddr = PREFIX_VIRT + endpointAddr;
    } else if (endpointAddrPrefix === PREFIX_VAR) {
      return null;
    } else if (endpointAddrPrefix === PREFIX_VAL) {
      return null;
    } else if (endpointAddrPrefix === PREFIX_FUNC) {
      return null;
    }

    if (!this.props.allEndpoints[endpointAddr]) {
      return null;
    }

    return Object.values(this.props.allEndpoints[endpointAddr].capabilities)
      .filter((capability) =>
        capability.capabilityType.includes(capabilityFilter)
      )
      .map((capability) => {
        return {
          key: capability.rawAttributeName,
          value: capability.name
            ? capability.name.normal
            : capability.rawAttributeName,
        };
      });
  }

  handleRuleCondAdd(key) {
    var rule = this.rulesCurrent[key];
    var newCond = {
      addrPrefix: "",
      addr: "testaddr",
      field: "field",
      op: "=",
      valueOrAddrPrefix: PREFIX_VAL,
      valueOrAddr: "",
      valueField: "value",
    };

    rule.cond = [...rule.cond, "AND", newCond];
    rule.toBeSaved = true;
    this.setState({
      rules: [
        ...this.state.rules.slice(0, key),
        rule,
        ...this.state.rules.slice(key + 1),
      ],
    });

    //this.debouncedRestUpdateRule(rule);
  }

  handleRuleOperAdd(key) {
    var rule = this.rulesCurrent[key];
    var newOper = {
      addrPrefix: "",
      addr: "testaddr",
      cmd: "cmd",
      values: "value",
    };

    rule.oper = [...rule.oper, newOper];
    rule.toBeSaved = true;
    this.setState({
      rules: [
        ...this.state.rules.slice(0, key),
        rule,
        ...this.state.rules.slice(key + 1),
      ],
    });

    //this.debouncedRestUpdateRule(rule);
  }

  handleRuleCondRemove(key, index) {
    var rule = this.rulesCurrent[key];

    rule.cond = [
      ...rule.cond.slice(0, index - 1),
      ...rule.cond.slice(index + 1),
    ];
    rule.toBeSaved = true;
    this.setState({
      rules: [
        ...this.state.rules.slice(0, key),
        rule,
        ...this.state.rules.slice(key + 1),
      ],
    });

    //this.debouncedRestUpdateRule(rule);
  }

  handleRuleOperRemove(key, index) {
    var rule = this.rulesCurrent[key];

    rule.oper = [...rule.oper.slice(0, index), ...rule.oper.slice(index + 1)];
    rule.toBeSaved = true;
    this.setState({
      rules: [
        ...this.state.rules.slice(0, key),
        rule,
        ...this.state.rules.slice(key + 1),
      ],
    });

    //this.debouncedRestUpdateRule(rule);
  }

  handleRuleAdd(addr) {
    let { prefix, address } = this.splitAddressAndPrefix(addr);
    var newCond = {
      addrPrefix: prefix,
      addr: address,
      field: "field",
      op: "=",
      valueOrAddrPrefix: PREFIX_VAL,
      valueOrAddr: "",
      valueField: "value",
    };

    var newOper = {
      addrPrefix: "",
      addr: "testaddr",
      cmd: "cmd",
      values: "value",
    };

    let nextRuleId =
      this.state.rules
        .filter((rule) => rule.endpointAddr === addr)
        .reduce((prev, curr) => (curr.ruleId > prev.ruleId ? curr : prev), {
          ruleId: 0,
        }).ruleId + 1;

    var newRule = {
      ruleId: nextRuleId,
      endpointAddr: addr,
      cond: [IF_COND_MODE_ANYCHANGED, newCond],
      oper: [newOper],
      editable: true,
      toBeSaved: true,
      toBeCreated: true,
    };

    this.rulesCurrent.push(newRule);
    this.setState({
      rules: [...this.state.rules, newRule],
    });

    //this.debouncedRestUpdateRule(newRule);
  }

  handleUpdateRule(key, newRule, invalid) {
    console.log("handleUpdateRule", key, newRule, invalid);
    this.rulesCurrent[key] = newRule;
    //newRule.invalidWarning = invalid;
    newRule.toBeSaved = true;
    this.setState({
      rules: [
        ...this.state.rules.slice(0, key),
        newRule,
        ...this.state.rules.slice(key + 1),
      ],
    });

    //this.debouncedRestUpdateRule(newRule);
  }

  handleRuleSave(key, newRule) {
    console.log("handleRuleSave", key, newRule);
    newRule.toBeSaved = false;
    newRule.toBeCreated = false;
    this.setState({
      rules: [
        ...this.state.rules.slice(0, key),
        newRule,
        ...this.state.rules.slice(key + 1),
      ],
    });

    this.debouncedRestUpdateRule(newRule);
  }

  restRemoveRule(endpointAddr, ruleId) {
    return fetch(
      window.serverAddr + "/api/endpoint/" + endpointAddr + "/rule/remove",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": window.token,
        },
        body: JSON.stringify({
          ruleId: ruleId,
        }),
        mode: "cors",
        credentials: "include",
      }
    )
      .then((res) => res.json())
      .then((result) => {
        //alert(result.status === 0 ? result.value.str : result);
        console.log(
          "removed rule:",
          result.status === 0 ? result.value.str : result
        );
      });
  }

  handleRemoveRule(key, endpointAddr, ruleId) {
    this.debouncedRestUpdateRule.flush();
    console.log("removing:", endpointAddr, ruleId);
    let toBeCreated = this.rulesCurrent[key].toBeCreated;
    delete this.rulesCurrent[key];
    //newRule.invalidWarning = invalid;
    this.setState({
      rules: [
        ...this.state.rules.slice(0, key),
        ...this.state.rules.slice(key + 1),
      ],
    });
    if (!toBeCreated) {
      this.restRemoveRule(endpointAddr, ruleId);
    }
  }

  renderRule(key, rule) {
    console.log("rendering rule:", rule);
    const editable = rule.editable ?? false;
    return (
      <div
        key={"rule_" + key}
        className={
          "parsedRuleItem " + (rule.invalidWarning ? "invalidWarning" : "")
        }
      >
        <div className="parsedRuleTitleItem">
          <div className="parsedRuleSubItem ruleId">{rule.ruleId}</div>
          <InplaceEdit
            key="endpointAddr"
            className="parsedRuleSubItem endpointAddr"
            editable={rule.toBeCreated}
            autoEditable={rule.toBeCreated}
            options={this.getCardEndpoints()}
            strict={true}
            value={rule.endpointAddr}
            onChange={(value, invalid) => {
              rule.endpointAddr = value;
              let nextRuleId =
                this.state.rules
                  .filter(
                    (ruleIt) =>
                      ruleIt.endpointAddr === rule.endpointAddr &&
                      ruleIt !== rule
                  )
                  .reduce(
                    (prev, curr) => (curr.ruleId > prev.ruleId ? curr : prev),
                    { ruleId: 0 }
                  ).ruleId + 1;
              rule.ruleId = nextRuleId;
              this.handleUpdateRule(key, rule, invalid);
            }}
          />

          <div className="parsedRuleSubItem ruleSaveButton">
            {rule.toBeSaved ? (
              <paper-button
                raised
                class="button"
                onClick={() => this.handleRuleSave(key, rule)}
              >
                Save
              </paper-button>
            ) : (
              <div></div>
            )}
          </div>
          <div
            className={"parsedRuleAction ruleCondAdd " + (editable ? "" : "")}
          >
            <paper-button
              raised
              class="button"
              onClick={() =>
                this.handleRemoveRule(key, rule.endpointAddr, rule.ruleId)
              }
            >
              x
            </paper-button>
          </div>
          <div className="parsedRuleSubEnd"></div>
        </div>
        <div className="parsedRuleSubItem ruleConst">IF</div>
        <div className="parsedRuleSubItem ruleCond">
          {rule.cond.map((ruleCond, ruleIndex) =>
            typeof ruleCond === "string" ? (
              ruleIndex === 0 ? (
                <InplaceEdit
                  key={"ruleCondMode_" + ruleIndex}
                  className="editable parsedRuleSubSubItem condCondMode"
                  editable={editable}
                  autoEditable={true}
                  options={IF_COND_MODE_LIST}
                  strict={true}
                  value={ruleCond}
                  onChange={(value, invalid) => {
                    rule.cond[ruleIndex] = value;
                    this.handleUpdateRule(key, rule, invalid);
                  }}
                />
              ) : (
                <InplaceEdit
                  key={"ruleCondConj_" + ruleIndex}
                  className="editable parsedRuleSubSubItem condConj"
                  editable={editable}
                  autoEditable={ruleIndex > 2 ? true : false}
                  options={ruleIndex > 2 ? ["AND", "OR"] : ["AND"]}
                  strict={true}
                  value={ruleCond}
                  onChange={(value, invalid) => {
                    rule.cond[ruleIndex] = value;
                    this.handleUpdateRule(key, rule, invalid);
                  }}
                />
              )
            ) : (
              <div key={ruleIndex} className="parsedRuleSubSubItem condCond">
                <InplaceEdit
                  key={"ruleCondaddrPrefix_" + ruleIndex}
                  className="editable parsedRuleSubSubSubItem condCondAddrPrefix"
                  editable={ruleIndex > 1 ? editable : false}
                  autoEditable={true}
                  options={
                    ruleIndex > 1
                      ? COND_PREFIX_LIST
                      : COND_PREFIX_LIST.filter(
                          (prefix) =>
                            prefix.key ===
                            this.splitAddressAndPrefix(rule.endpointAddr).prefix
                        )
                  }
                  strict={true}
                  value={ruleCond.addrPrefix}
                  onChange={(value, invalid) => {
                    ruleCond.addrPrefix = value;
                    this.handleUpdateRule(key, rule, invalid);
                  }}
                />
                <InplaceEdit
                  key={"ruleCondaddr_" + ruleIndex}
                  className="editable parsedRuleSubSubSubItem condCondAddr"
                  editable={ruleIndex > 1 ? editable : false}
                  autoEditable={true}
                  options={
                    ruleIndex > 1
                      ? this.filterByPrefix(ruleCond.addrPrefix, "Attribute")
                      : [
                          {
                            key: this.splitAddressAndPrefix(rule.endpointAddr)
                              .address,
                            value: this.getEndpointName(
                              this.props.allEndpoints[rule.endpointAddr]
                            ),
                          },
                        ]
                  }
                  strict={true}
                  value={ruleCond.addr}
                  onChange={(value, invalid) => {
                    ruleCond.addr = value;
                    this.handleUpdateRule(key, rule, invalid);
                  }}
                />
                <InplaceEdit
                  key={"ruleCondfield_" + ruleIndex}
                  className="editable parsedRuleSubSubSubItem condCondField"
                  editable={editable}
                  autoEditable={true}
                  options={this.filterRawAttrByEndpoint(
                    ruleCond.addrPrefix,
                    ruleCond.addr,
                    "Attribute"
                  )}
                  strict={true}
                  value={ruleCond.field}
                  onChange={(value, invalid) => {
                    ruleCond.field = value;
                    this.handleUpdateRule(key, rule, invalid);
                  }}
                />
                <InplaceEdit
                  key={"ruleCondop_" + ruleIndex}
                  className="editable parsedRuleSubSubSubItem condCondOp"
                  editable={editable}
                  autoEditable={true}
                  options={OPERATOR_LIST}
                  strict={true}
                  value={ruleCond.op}
                  onChange={(value, invalid) => {
                    ruleCond.op = value;
                    this.handleUpdateRule(key, rule, invalid);
                  }}
                />
                <InplaceEdit
                  key={"ruleCondvalueOrAddrPrefix_" + ruleIndex}
                  className="editable parsedRuleSubSubSubItem condCondValueOrAddrPrefix"
                  editable={editable}
                  autoEditable={true}
                  options={COND_PREFIX_LIST}
                  strict={true}
                  value={ruleCond.valueOrAddrPrefix}
                  onChange={(value, invalid) => {
                    ruleCond.valueOrAddrPrefix = value;
                    this.handleUpdateRule(key, rule, invalid);
                  }}
                />
                <InplaceEdit
                  key={"ruleCondvalueOrAddr_" + ruleIndex}
                  className="editable parsedRuleSubSubSubItem condCondValueOrAddr"
                  editable={editable}
                  autoEditable={true}
                  options={this.filterByPrefix(
                    ruleCond.valueOrAddrPrefix,
                    "Attribute"
                  )}
                  strict={true}
                  value={ruleCond.valueOrAddr}
                  onChange={(value, invalid) => {
                    ruleCond.valueOrAddr = value;
                    this.handleUpdateRule(key, rule, invalid);
                  }}
                />
                <InplaceEdit
                  key={"ruleCondvalueField_" + ruleIndex}
                  className="editable parsedRuleSubSubSubItem condCondValueField"
                  editable={editable}
                  autoEditable={true}
                  options={this.filterRawAttrByEndpoint(
                    ruleCond.valueOrAddrPrefix,
                    ruleCond.valueOrAddr,
                    "Attribute"
                  )}
                  strict={true}
                  value={ruleCond.valueField}
                  onChange={(value, invalid) => {
                    ruleCond.valueField = value;
                    this.handleUpdateRule(key, rule, invalid);
                  }}
                />
                {ruleIndex > 1 ? (
                  <div
                    key={"ruleCondRemove_" + ruleIndex}
                    className="parsedRuleSubSubSubRemove"
                    onClick={() => this.handleRuleCondRemove(key, ruleIndex)}
                  >
                    x
                  </div>
                ) : (
                  <div></div>
                )}
                <div
                  key={"ruleCondEnd_" + ruleIndex}
                  className="parsedRuleSubSubSubEnd"
                ></div>
              </div>
            )
          )}
          <div className="parsedRuleSubSubEnd"></div>
        </div>
        <div className={"parsedRuleAction ruleCondAdd " + (editable ? "" : "")}>
          <paper-button
            raised
            class="button"
            onClick={() => this.handleRuleCondAdd(key)}
          >
            +
          </paper-button>
        </div>
        <div className="parsedRuleSubItem ruleConst">THEN</div>
        <div className="parsedRuleSubItem ruleOper">
          {rule.oper.map((ruleOper, ruleIndex) => (
            <div key={ruleIndex} className="parsedRuleSubSubItem oper">
              <InplaceEdit
                key={"ruleOperaddrPrefix_" + ruleIndex}
                className="editable parsedRuleSubSubSubItem operAddrPrefix"
                editable={editable}
                autoEditable={true}
                options={OPER_PREFIX_LIST}
                strict={true}
                value={ruleOper.addrPrefix}
                onChange={(value, invalid) => {
                  ruleOper.addrPrefix = value;
                  this.handleUpdateRule(key, rule, invalid);
                }}
              />
              <InplaceEdit
                key={"ruleOperaddr_" + ruleIndex}
                className="editable parsedRuleSubSubSubItem operAddr"
                editable={editable}
                autoEditable={true}
                options={this.filterByPrefix(ruleOper.addrPrefix, "Command")}
                strict={true}
                value={ruleOper.addr}
                onChange={(value, invalid) => {
                  ruleOper.addr = value;
                  this.handleUpdateRule(key, rule, invalid);
                }}
              />
              <InplaceEdit
                key={"ruleOpercmd_" + ruleIndex}
                className="editable parsedRuleSubSubSubItem operCmd"
                editable={editable}
                autoEditable={true}
                options={this.filterRawAttrByEndpoint(
                  ruleOper.addrPrefix,
                  ruleOper.addr,
                  "Command"
                )}
                strict={true}
                value={ruleOper.cmd}
                onChange={(value, invalid) => {
                  ruleOper.cmd = value;
                  this.handleUpdateRule(key, rule, invalid);
                }}
              />
              <InplaceEdit
                key={"ruleOpervalues_" + ruleIndex}
                className="editable parsedRuleSubSubSubItem operValues"
                editable={editable}
                autoEditable={true}
                value={ruleOper.values}
                onChange={(value, invalid) => {
                  ruleOper.values = value;
                  this.handleUpdateRule(key, rule, invalid);
                }}
              />
              {ruleIndex !== 0 ? (
                <div
                  key={"ruleCondRemove_" + ruleIndex}
                  className="parsedRuleSubSubSubRemove"
                  onClick={() => this.handleRuleOperRemove(key, ruleIndex)}
                >
                  x
                </div>
              ) : (
                <div></div>
              )}
              <div
                key={"ruleOperend_" + ruleIndex}
                className="parsedRuleSubSubSubEnd"
              ></div>
            </div>
          ))}
          <div className="parsedRuleSubSubEnd"></div>
        </div>
        <div className={"parsedRuleAction ruleOperAdd " + (editable ? "" : "")}>
          <paper-button
            raised
            class="button"
            onClick={() => this.handleRuleOperAdd(key)}
          >
            +
          </paper-button>
        </div>
        <div className="parsedRuleSubEnd"></div>
      </div>
    );
  }

  render() {
    return (
      <div className="rules">
        {this.rulesCurrent.map((ruleJson, key) =>
          this.renderRule(key, ruleJson)
        )}
        <div className="parsedRuleAction ruleAdd">
          <paper-button
            raised
            class="button"
            onClick={() => this.handleRuleAdd(this.endpoints[0].endpointAddr)}
          >
            Add rule
          </paper-button>
        </div>
      </div>
    );
  }
}
