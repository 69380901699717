import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { wsInit, wsDisconnect, wsReconnect } from "./ws.js";
import _, { add } from "lodash";
import Board from "./board.js";
import debounce from "lodash.debounce";

var inputEndpoints = {};
var inputCards = {};
var inputLayouts = {};
var inputMappers = {};
var boardRef = React.createRef();

const DATA_LIMITS = {
  raw: 160,
  hour: 40,
  day: 40,
  month: 8,
  week: 8,
};

window.serverAddr = "";
if (window.location.hash) {
  window.serverAddr = window.location.hash.substring(1);
  console.log("using server address:", window.serverAddr);
}

if (window.serverAddr) {
  fetch(window.serverAddr + "/indexReact", {
    mode: "cors",
    credentials: "include",
  })
    .then((res) => res.text())
    .then((result) => {
      const regex = /window.token="[a-zA-Z0-9]*"/g;
      const found = result.match(regex);
      const regex2 = /".*"/g;
      const found2 = found[0].match(regex2);
      const found3 = found2[0].substring(1, found2[0].length - 1);
      window.token = found3;
      document.cookie = "csrftoken=" + window.token + "; SameSite=None; Secure";
      console.log("got external token:", window.token);
    });
}

function fetchEndpoints(callback) {
  fetch(window.serverAddr + "/api/get/endpoints", {
    mode: "cors",
    credentials: "include",
  })
    .then((res) => res.json())
    .then((result) => {
      inputEndpoints = result.endpointDict;
      inputMappers = result.mappersList;
      console.log("inputEndpoints", inputEndpoints);
      console.log("inputMappers", inputMappers);

      for (let endpointKey in inputEndpoints) {
        if (!inputEndpoints[endpointKey]) continue;

        let types = inputEndpoints[endpointKey].capabilities
          .reduce(
            (arr, capability) => arr.concat(capability.capabilityType),
            []
          )
          .filter((v, i, a) => a.indexOf(v) === i)
          .sort();
        inputEndpoints[endpointKey]["capabilityTypes"] = types;
      }
      fetch(window.serverAddr + "/api/get/cards", {
        mode: "cors",
        credentials: "include",
      })
        .then((res) => res.json())
        .then((result) => {
          inputCards = result;
          console.log("inputCards", inputCards);

          fetch(window.serverAddr + "/api/get/cardsLayout", {
            mode: "cors",
            credentials: "include",
          })
            .then((res) => res.json())
            .then((result) => {
              inputLayouts = result;
              console.log("inputLayouts", inputLayouts);

              for (let gridId in inputLayouts) {
                for (let breakpoint in inputLayouts[gridId]) {
                  let currentLayout = inputLayouts[gridId][breakpoint];
                  for (let cardIndex in currentLayout) {
                    currentLayout[cardIndex].static = true;
                  }
                }
              }
              callback();
            });
        });
    });
}

function updateTimestamps(endpointObj, timestamp) {
  if (!endpointObj.timestamps) return;
  endpointObj.timestamps.unshift(timestamp);
}

function updateCapabilityRawValue(capability, rawValue) {
  if (!capability.rawValues) return;
  capability.rawValues.unshift(rawValue);
}

function convertToType(t, e) {
  return t.constructor(e);
}

function typeDefaultValue(t) {
  return t.constructor();
}

function updateCapabilityValues(capability, endpoint) {
  if (!capability.rawValues) return;

  let rawAttributes = endpoint.capabilities.reduce(
    (a, v) => ({
      ...a,
      [v.rawAttributeName]: v.rawValues ? v.rawValues[0] : null,
    }),
    {}
  );
  let rawValue = capability.rawValues[0];
  let value = rawValue;
  let condition = capability.condition;
  let translator = capability.translator;

  if (
    condition &&
    convertToType(
      condition["value"],
      rawAttributes[condition["rawAttributeName"]] ??
        typeDefaultValue(condition["value"])
    ) !== condition["value"]
  )
    value = null;

  if (translator) value = translator["dict"][value] ?? translator["default"];

  let oldValue = capability.values[0];
  capability.values.unshift(value);
  if (oldValue !== value) {
    capability.lastChange = capability.values.length - 1;
  }

  if (capability.valuesPercent) {
    let percentValue =
      (parseFloat(value) - capability.rangeMin) /
      (capability.rangeMax - capability.rangeMin);
    capability.valuesPercent.unshift(percentValue);
  }
}

function loadData(endpointIds, meta, period) {
  const aggregationMode = meta.detailsConfig.dataAggregation ?? [];
  var limit = DATA_LIMITS[period] ?? 160;

  console.log("loadData", endpointIds);
  var endpoints = JSON.parse(JSON.stringify(boardRef.current.state.endpoints));
  for (let it in endpointIds) {
    let endpointId = endpointIds[it];
    let endpoint = endpoints[endpointId];
    if (!endpoint) {
      console.log("loadData, endpoint not found", endpointId);
      continue;
    }
    var timestamps;
    if ("raw" === period) {
      timestamps = endpoint.timestamps;
    } else {
      timestamps = endpoint["timestamps_" + period] ?? [];
    }
    if (timestamps.length >= limit) {
      console.log(
        "loadData, endpoint already has data length >= limit: ",
        endpointId,
        endpoint.timestamps.length
      );
      continue;
    }

    fetch(
      window.serverAddr +
        "/api/endpoint/" +
        endpoint.endpointAddr +
        "/" +
        period +
        "/?limit=" +
        limit +
        "&offset=0" +
        "&aggregation=" +
        encodeURIComponent(JSON.stringify(aggregationMode)) +
        "&keepLast=1",
      {
        mode: "cors",
        credentials: "include",
      }
    )
      .then((res) => res.json())
      .then((result) => {
        if ("raw" === period) {
          endpoint["capabilities"] = result.capabilities;
          endpoint["timestamps"] = result.timestamps;
        } else {
          endpoint["capabilities_" + period] = result.capabilities;
          endpoint["timestamps_" + period] = result.timestamps;
        }
        boardRef.current.setState({
          endpoints: endpoints,
        });
      });
  }
}

function updateLocation(endpointAddr, value) {
  fetch(window.serverAddr + "/api/request/endpointSetLocation", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": window.token,
    },
    body: JSON.stringify({
      devAddr: endpointAddr,
      location: value,
      execute: true,
    }),
    mode: "cors",
    credentials: "include",
  })
    .then((res) => res.json())
    .then((result) => {
      console.log("endpointSetLocation", result);
    });
}
let debouncedUpdateLocation = debounce(
  (endpointAddr, value) => updateLocation(endpointAddr, value),
  500
);

function handleLocationChange(endpointId, value) {
  console.log("handleLocationChange", endpointId, value);
  let origEndpoint = boardRef.current.state.endpoints[endpointId];
  var endpoint = { ...origEndpoint, location: value };
  console.log("handleLocationChange endpoint:", endpoint);
  boardRef.current.setState({
    endpoints: { ...boardRef.current.state.endpoints, [endpointId]: endpoint },
  });
  debouncedUpdateLocation(endpoint.endpointAddr, value);
}

function onWsRecv(text) {
  let updateObj = JSON.parse(text);
  var endpoints = JSON.parse(JSON.stringify(boardRef.current.state.endpoints));
  for (let index in updateObj.data) {
    let data = updateObj.data[index];
    console.log("got update for", data);
    let address = data.address;
    let type = data.type;
    if ("removed" === type) {
      delete endpoints[address];
    } else if ("joined" === type) {
      let typeName = "" + data.manufacturer + "_" + data.model;
      let mapperName = data.mapperName ?? "defaultMapper";
      let mapperIt = inputMappers.filter((mapper) => mapper.id === typeName);
      let mapper = mapperIt[0] ?? {
        capabilities: { capabilityList: [] },
        displayName: "default",
      };

      let newEndpoint = {
        battery: 0,
        capabilities: mapper.capabilities.capabilityList,
        capabilityTypes: [],
        data: [],
        deleted: 0,
        endpointAddr: address,
        endpointTypeName: typeName,
        joined: 1,
        lastSeen: new Date().getTime(),
        location: null,
        lqi: 255,
        manufacturer: data.manufacturer,
        mapperNameRaw: mapperName,
        mapperId: typeName,
        mapperName: mapper.displayName,
        meta: { deviceDataFieldsMeta: {} },
        model: data.model,
        name: address,
        online: true,
        timestamps: [],
      };

      console.log("added endpoint:", newEndpoint);

      endpoints[address] = newEndpoint;
    } else if ("report" === type) {
      var currentEndpoint = endpoints[address];
      if (!currentEndpoint) continue;

      updateTimestamps(currentEndpoint, data.timestamp);
      for (let capIt in currentEndpoint.capabilities) {
        var capability = currentEndpoint.capabilities[capIt];
        if (Object.keys(data.report).includes(capability.rawAttributeName)) {
          updateCapabilityRawValue(
            capability,
            data.report[capability.rawAttributeName]
          );
        }
      }
      for (let capIt in currentEndpoint.capabilities) {
        var capability = currentEndpoint.capabilities[capIt];
        updateCapabilityValues(capability, currentEndpoint);
      }
    }
  }
  boardRef.current.setState({
    endpoints: endpoints,
  });
}

fetchEndpoints(() => {
  ReactDOM.render(
    <Board
      ref={boardRef}
      cards={inputCards}
      inputEndpoints={inputEndpoints}
      inputMappers={inputMappers}
      inputLayouts={inputLayouts}
      loadData={(endpointIds, meta, period) =>
        loadData(endpointIds, meta, period)
      }
      handleLocationChange={(endpointId, value) =>
        handleLocationChange(endpointId, value)
      }
      fullReload={() =>
        fetchEndpoints(() => {
          boardRef.current.setState({
            endpoints: inputEndpoints,
            mappers: inputMappers,
            inputLayouts: inputLayouts,
            cards: inputCards,
          });
        })
      }
      onLogin={() => wsReconnect(false)}
      onLogout={() => wsDisconnect()}
    />,
    document.getElementById("root")
  );

  //setupAutoRefresh();
  wsInit(
    (text) => onWsRecv(text),
    () => boardRef.current.setState({ wsConnected: true }),
    () => boardRef.current.setState({ wsConnected: false }),
    () => {}
  );
});
