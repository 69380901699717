var wsUri;
var websocket;
var websocketConnected = false;
var onOpenCb = () => {};
var onCloseCb = () => {};
var onErrorCb = () => {};
var onRecvCb = (text) => {};

function setupWsUri() {
  var loc = window.location.host;
  if (window.serverAddr) {
    const { host } = new URL(window.serverAddr);
    loc = host;
  }
  if (window.location.protocol === "https:") {
    wsUri = "wss:";
  } else {
    wsUri = "ws:";
  }
  wsUri += "//" + loc;
  wsUri += "/ws/";
  console.log("ws uri: ", wsUri);
}

export function wsInit(onRecv, onOpen, onClose, onError) {
  setupWsUri();
  websocket = new WebSocket(wsUri);
  websocket.onopen = function (evt) {
    onOpen(evt);
  };
  websocket.onclose = function (evt) {
    onClose(evt);
  };
  websocket.onmessage = function (evt) {
    onMessage(evt);
  };
  websocket.onerror = function (evt) {
    onError(evt);
  };
  onOpenCb = onOpen;
  onCloseCb = onClose;
  onErrorCb = onError;
  onRecvCb = onRecv;
}

export function wsConnect() {
  setupWsUri();
  if (!websocketConnected) {
    websocket = new WebSocket(wsUri);
    websocket.onopen = function (evt) {
      onOpen(evt);
    };
    websocket.onclose = function (evt) {
      onClose(evt);
    };
    websocket.onmessage = function (evt) {
      onMessage(evt);
    };
    websocket.onerror = function (evt) {
      onError(evt);
    };
  }
}

export function wsReconnect(force) {
  if (!websocketConnected || force) {
    if (websocket) {
      websocket.close();
      websocket = null;
    }
    setTimeout(() => wsConnect(), 500);
  }
}

export function wsDisconnect() {
  if (websocket) {
    websocket.close();
    websocket = null;
  }
}

function onOpen(evt) {
  websocketConnected = true;
  console.log("connected to ws", wsUri);
  onOpenCb();
}

function onClose(evt) {
  websocketConnected = false;
  console.log("ws disconnected", wsUri);
  onCloseCb();
}

function onMessageTextInt(text) {
  console.log("ws recv", text.trim());
  onRecvCb(text.trim());
}

function onMessage(evt) {
  onMessageTextInt(evt.data);
}

function onError(evt) {
  console.log("ws error", evt.data);
  onErrorCb();
}

export function wsSend(message) {
  console.log("ws send", message);
  websocket.send(message);
}
