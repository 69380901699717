import React from "react";
import Card from "./endpoints/card.js";
import { Accordion, AccordionTab } from "./components/accordion.js";
import "/node_modules/react-resizable/css/styles.css";
import "@polymer/paper-button/paper-button.js";
import debounce from "lodash.debounce";
import _ from "lodash";
import CardEditDialog from "./cardEditDialog.js";
import CardDetailsDialog from "./cardDetailsDialog.js";
import CardGrid from "./cardGrid.js";
import { Tabs, Tab } from "./components/tabs.js";
import LoginDialog from "./loginDialog.js";
import { Toggle } from "./components/toggle.js";
import AddDeviceDialog from "./addDeviceDialog.js";
import EndpointDevice from "./endpoints/endpointDevice.js";

const defaultMeta = {
  attributes: {
    required: [],
    supported: [],
    allowMultiple: true,
  },
  cardConfig: {
    minW: 2,
    minH: 1,
  },
};

export default class Board extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cards: props.cards,
      endpoints: props.inputEndpoints,
      mappers: props.inputMappers,
      inputLayouts: props.inputLayouts,
      locked: true,
      darkMode: JSON.parse(localStorage.getItem("darkMode")) ?? false,
      cardEditDialogCardId: null,
      cardEditDialogOpen: false,
      cardDetailsDialogCardId: null,
      cardDetailsDialogOpen: false,
      loginDialogOpen: false,
      addDeviceDialogOpen: false,
      cardMetas: {},
      loginUsername: null,
      loginPermWrite: false,
      wsConnected: false,
      visibleTab: "dashboard",
    };
    this.debouncedOnCardUpdate = debounce(
      (cardId, cardName, typeName, endpointNames, remove = false) =>
        this.onCardUpdate(cardId, cardName, typeName, endpointNames, remove),
      500
    );
    this.getUser(true);
  }

  getUser(startup) {
    fetch(window.serverAddr + "/api/session/getUser", {
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": window.token,
      },
      mode: "cors",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("getUser", result);
        this.handleLoginStatus("confirmed", result);
        if (startup && (!result || !result.username)) {
          this.setState({ loginDialogOpen: true });
        }
      });
  }

  onCardUpdate(cardId, cardName, typeName, endpointNames, remove = false) {
    fetch(window.serverAddr + "/api/request/cardUpdate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": window.token,
      },
      body: JSON.stringify({
        cardId: cardId,
        cardName: cardName,
        typeName: typeName,
        endpointNames: endpointNames,
        remove: remove,
      }),
      mode: "cors",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("onCardUpdate", result);
      });
  }

  handleMetaUpdate(cardId, meta) {
    console.log("handleMetaUpdate for: ", cardId, "meta: ", meta);
    var newCardMetas = { ...this.state.cardMetas, [cardId]: meta };
    this.setState({ cardMetas: newCardMetas });
  }

  handleLockChange() {
    let locked = !this.state.locked;
    this.setState({
      locked: locked,
    });
  }

  handleDarkModeChange() {
    let darkMode = !this.state.darkMode;
    this.setState({
      darkMode: darkMode,
    });
    localStorage.setItem("darkMode", JSON.stringify(darkMode));
  }

  handleNewCard() {
    let lastId = Object.values(this.state.cards).reduce(
      (prev, curr) => Math.max(prev, curr.id.match(/\d+/)[0]),
      0
    );
    let newId = lastId + 1;
    let newCardId = "cardId" + newId;
    let newCardName = "cardName" + newId;
    let newCard = {
      id: newCardId,
      name: newCardName,
      typeName: "Endpoint",
      endpointNames: [],
    };
    var newCards = { ...this.state.cards, [newCardId]: newCard };
    this.setState({
      cards: newCards,
    });
    this.handleMetaUpdate(newCardId, defaultMeta);
    this.onCardUpdate(newCardId, newCardName, newCard.typeName, []);
    this.handleDialogCardEditOpen(newCardId, defaultMeta);
  }

  handleRemoveCard(cardId) {
    var newCards = { ...this.state.cards };
    delete newCards[cardId];

    var newMetas = { ...this.state.cardMetas };
    delete newMetas[cardId];

    this.setState({
      cards: newCards,
      cardMetas: newMetas,
    });

    this.onCardUpdate(cardId, "", "", [], true);
  }

  handleDialogCardEditOpen(cardEditDialogCardId, meta) {
    var newCardMetas = {
      ...this.state.cardMetas,
      [cardEditDialogCardId]: meta,
    };
    this.setState({
      cardEditDialogCardId: cardEditDialogCardId,
      cardMetas: newCardMetas,
      cardEditDialogOpen: true,
    });
  }

  handleDefaultOnClick(cardId) {
    this.setState({
      cardDetailsDialogCardId: cardId,
      cardDetailsDialogOpen: true,
    });
  }

  handleDialogCardEditClose() {
    this.setState({ cardEditDialogOpen: false });
  }

  handleDialogCardDetailsClose() {
    this.setState({ cardDetailsDialogOpen: false });
  }

  handleDialogLoginClose() {
    this.setState({ loginDialogOpen: false });
  }

  handleDialogAddDeviceClose() {
    this.setState({ addDeviceDialogOpen: false });
  }

  handleLoginStatus(state, data) {
    console.log("handleLoginStatus", state, data);
    if (state === "sent") {
      //awaiting response
    } else if (state === "logoutSent") {
      //awaiting response
    } else if (state === "finished") {
      if (data) {
        //ok
        this.getUser(false);
      } else {
        //wrong username/password
        this.setState({
          loginUsername: null,
          loginPermWrite: false,
        });
      }
    } else if (state === "confirmed") {
      //{username, permWrite}
      this.setState({
        loginUsername: data.username,
        loginPermWrite: data.permWrite,
      });
      this.props.onLogin();
    } else if (state === "logout") {
      this.setState({
        loginUsername: null,
        loginPermWrite: false,
      });
      this.props.onLogout();
    }

    if (!this.props.inputEndpoints || !this.props.inputEndpoints.length) {
      if (this.state.loginUsername) {
        this.props.fullReload();
      }
    }
  }

  handleDialogCardEditCheck(cardId, endpointName) {
    let card = this.state.cards[cardId];
    let endpointNames = card.endpointNames;
    let cardName = card.name;
    var newEndpointNames = [...endpointNames];
    if (endpointNames.includes(endpointName)) {
      newEndpointNames = endpointNames.filter((name) => {
        return name !== endpointName;
      });
    } else {
      newEndpointNames.push(endpointName);
    }
    let newCard = { ...card, endpointNames: newEndpointNames };
    this.setState({
      cards: { ...this.state.cards, [cardId]: newCard },
    });
    this.debouncedOnCardUpdate(
      cardId,
      cardName,
      card.typeName,
      newEndpointNames
    );
  }

  handleDialogCardEditName(cardId, newName) {
    let card = this.state.cards[cardId];
    let endpointNames = card.endpointNames;

    let newCard = { ...card, name: newName };
    this.setState({
      cards: { ...this.state.cards, [cardId]: newCard },
    });
    this.debouncedOnCardUpdate(cardId, newName, card.typeName, endpointNames);
  }

  handleDialogCardEditType(cardId, newType) {
    let card = this.state.cards[cardId];
    let endpointNames = card.endpointNames;
    let cardName = card.name;

    let newCard = { ...card, typeName: newType, endpointNames: [] };
    this.setState({
      cards: { ...this.state.cards, [cardId]: newCard },
    });
    this.debouncedOnCardUpdate(
      cardId,
      cardName,
      newCard.typeName,
      endpointNames
    );
  }

  handleLoadData(endpointIds, meta, period) {
    return this.props.loadData(endpointIds, meta, period);
  }

  render() {
    var locations = EndpointDevice.getStaticMeta().defaultLocations;
    locations = [
      ...new Set(
        locations.concat(
          ...Object.values(this.state.endpoints)
            .map((endpoint) => endpoint.location)
            .filter((location) => location && location.length)
        )
      ),
    ];

    return (
      <div
        id="main"
        className={this.state.darkMode ? "themeDark" : "themeLight"}
      >
        <div className="tabsHeader">
          <Tabs
            items={[
              { label: "Dashboard", state: "dashboard" },
              { label: "Devices", state: "devices" },
              {
                label: (
                  <div className="user">
                    <img
                      src={
                        this.state.loginUsername
                          ? this.state.loginPermWrite
                            ? "admin.png"
                            : "user.png"
                          : "login.png"
                      }
                      alt={
                        this.state.loginUsername
                          ? "" +
                            (this.state.loginPermWrite ? "admin:" : "user:") +
                            " " +
                            this.state.loginUsername
                          : "login"
                      }
                    />
                  </div>
                ),
                state: "user",
                hideonoverlow: true,
                align: "right",
                customHandler: (state) => {
                  this.setState({ loginDialogOpen: true });
                },
              },
              {
                label: this.state.locked ? "Unlock" : "Lock",
                state: "locked",
                customHandler: (state) => {
                  this.handleLockChange();
                },
                hideonoverlow: true,
                align: "right",
              },
              {
                label: this.state.darkMode ? "Light" : "Dark",
                state: "darkMode",
                customHandler: (state) => {
                  this.handleDarkModeChange();
                },
                hideonoverlow: true,
                align: "right",
              },
            ]}
            setState={(state) => {
              this.setState({ visibleTab: state });
            }}
            visibleTab={this.state.visibleTab}
          >
            <div
              noselect="true"
              hidden={this.state.wsConnected}
              align="right"
              style={{ color: "red" }}
            >
              {this.state.wsConnected ? "Connected" : "Lost connection"}
            </div>
            <div
              noselect="true"
              hidden={this.state.loginPermWrite}
              align="right"
            >
              {this.state.loginPermWrite ? "Full access" : "Read only"}
            </div>
          </Tabs>
        </div>
        <div className="tabsContent">
          <Tab
            title="dashboard"
            visible={this.state.visibleTab === "dashboard"}
          >
            <div className="topButtons">
              <div className={this.state.locked ? "hidden" : "visible"}>
                <paper-button
                  raised
                  class="button"
                  onClick={() => this.handleNewCard()}
                >
                  New card
                </paper-button>
              </div>
            </div>
            <CardGrid
              gridId="dashboard"
              inputLayouts={this.state.inputLayouts["dashboard"]}
              cards={this.state.cards}
              cardMetas={this.state.cardMetas}
              endpoints={this.state.endpoints}
              locked={this.state.locked}
              handleMetaUpdate={(cardId, meta) =>
                this.handleMetaUpdate(cardId, meta)
              }
              handleDialogCardEditOpen={(cardId, metaRef) =>
                this.handleDialogCardEditOpen(cardId, metaRef)
              }
              handleDefaultOnClick={(cardId) =>
                this.handleDefaultOnClick(cardId)
              }
              handleLoadData={(endpointIds, meta, period) =>
                this.handleLoadData(endpointIds, meta, period)
              }
            />
          </Tab>
          <Tab title="devices" visible={this.state.visibleTab === "devices"}>
            <div className="topButtons">
              <div>
                <paper-button
                  raised
                  class="button"
                  onClick={() => {
                    this.setState({ addDeviceDialogOpen: true });
                  }}
                >
                  Add devices
                </paper-button>
              </div>
            </div>
            <div className="devices">
              <table>
                <tbody>
                  {Object.values(this.state.endpoints).map((endpoint) => {
                    let key = "dev_" + endpoint.endpointAddr;
                    return (
                      <tr
                        id={"cardDiv_" + key}
                        key={"cardDiv_" + key}
                        className="deviceCardTr"
                      >
                        <EndpointDevice
                          key={"device_" + key}
                          detailsMode={false}
                          endpoints={[endpoint]}
                          locked={this.state.locked}
                          contextCard={null}
                          locations={locations}
                          allEndpoints={this.state.endpoints}
                          handleDialogCardEditOpen={(cardId, metaRef) => {}}
                          handleCardConfigUpdate={(cardId, cardConfig) => {}}
                          handleDefaultOnClick={(cardId) => {}}
                          handleLoadData={(endpointIds, meta, period) => {}}
                          handleLocationChange={(endpointId, value) =>
                            this.props.handleLocationChange(endpointId, value)
                          }
                        />
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Tab>
        </div>
        <CardEditDialog
          open={this.state.cardEditDialogOpen}
          cardId={this.state.cardEditDialogCardId}
          cardMetas={this.state.cardMetas}
          cards={this.state.cards}
          endpoints={this.state.endpoints}
          handleClose={() => this.handleDialogCardEditClose()}
          handleRemoveCard={(cardId) => this.handleRemoveCard(cardId)}
          handleDialogCardEditCheck={(cardId, endpointKey) =>
            this.handleDialogCardEditCheck(cardId, endpointKey)
          }
          handleDialogCardEditName={(cardId, name) =>
            this.handleDialogCardEditName(cardId, name)
          }
          handleDialogCardEditType={(cardId, cardType) =>
            this.handleDialogCardEditType(cardId, cardType)
          }
          onClose={() => this.handleDialogCardEditClose()}
        />
        <CardDetailsDialog
          open={this.state.cardDetailsDialogOpen}
          cardId={this.state.cardDetailsDialogCardId}
          cardMetas={this.state.cardMetas}
          cards={this.state.cards}
          endpoints={this.state.endpoints}
          handleClose={() => this.handleDialogCardDetailsClose()}
          onClose={() => this.handleDialogCardDetailsClose()}
          handleLoadData={(endpointIds, meta, period) =>
            this.handleLoadData(endpointIds, meta, period)
          }
        />
        <LoginDialog
          open={this.state.loginDialogOpen}
          handleClose={() => this.handleDialogLoginClose()}
          onClose={() => this.handleDialogLoginClose()}
          handleLoginStatus={(state, data) =>
            this.handleLoginStatus(state, data)
          }
          username={this.state.loginUsername}
        />
        <AddDeviceDialog
          open={this.state.addDeviceDialogOpen}
          handleClose={() => this.handleDialogAddDeviceClose()}
          onClose={() => this.handleDialogAddDeviceClose()}
          inputMappers={this.state.mappers}
          allEndpoints={this.state.endpoints}
        />
      </div>
    );
  }
}
