import React from "react";
import "./css-circular-prog-bar.css";

export class ProgressCircle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="progressCircleAnimation">
        <div
          className={
            "progress-circle " +
            ("p" + Math.round(this.props.valuePercent)) +
            " " +
            (this.props.valuePercent > 50 ? "over50" : "")
          }
        >
          <span className="permitTimeLeft">{this.props.displayValue}</span>
          <div className="left-half-clipper">
            <div className="first50-bar"></div>
            <div className="value-bar"></div>
          </div>
        </div>
      </div>
    );
  }
}
