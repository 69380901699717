import { add } from "lodash";
import React from "react";

export class InplaceEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      localEditable: false,
      localInvalid: false,
    };
  }

  getClassNames(additional = []) {
    if (this.state.localInvalid) {
      additional.push("invalidWarning");
    }
    if (this.props.autoEditable) {
      additional.push("autoeditable");
    }
    return this.props.className + " " + additional.join(" ");
  }

  setInvalid(value) {
    this.setState({
      localInvalid: true,
    });
    this.props.onChange(value, true);
  }

  parseOption(option) {
    if (
      option.key !== null &&
      option.key !== undefined &&
      option.key !== false
    ) {
      return option;
    }

    if (typeof option === "string") {
      return { key: option, value: option };
    }

    return { key: Object.keys(option)[0], value: Object.values(option)[0] };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      (this.props.editable &&
        (!prevProps.editable ||
          JSON.stringify(prevProps.options) !==
            JSON.stringify(this.props.options))) ||
      (this.props.autoEditable &&
        JSON.stringify(prevProps.options) !==
          JSON.stringify(this.props.options))
    ) {
      if (
        this.props.options === null ||
        this.props.options === undefined ||
        this.props.options.length === 0
      ) {
        return true;
      }

      if (!this.props.strict) {
        return true;
      }

      if (this.props.options === false) {
        if (this.props.value !== "") {
          console.log(
            "inplaceEditor value expects empty string, defaulting",
            this.props.value
          );
          this.setInvalid("");
        }
        return true;
      }

      if (
        this.props.options.filter(
          (option) => this.parseOption(option).key === this.props.value
        ).length === 0
      ) {
        console.log(
          "inplaceEditor value is not an option, defaulting:",
          this.props.value,
          this.parseOption(this.props.options[0]).key
        );
        this.setInvalid(this.parseOption(this.props.options[0]).key);
      }
    }
    return true;
  }

  componentDidMount() {
    this.componentDidUpdate({}, {}, {});
  }

  handleOnChange(event) {
    event.stopPropagation();
    this.setState({
      localInvalid: false,
    });
    if (this.props.onChange) this.props.onChange(event.target.value, false);
  }

  handleOnClick(event) {
    event.stopPropagation();
    if (this.props.autoEditable) {
      this.setState({
        localEditable: !this.state.localEditable,
        localInvalid: false,
      });
    }
    if (this.props.onClick) this.props.onClick(event);
  }

  handleOnBlur(event) {
    if (this.props.autoEditable) {
      this.setState({
        localEditable: false,
        localInvalid: false,
      });
    }
    if (this.props.onBlur) this.props.onBlur(event);
  }

  renderDiv() {
    return (
      <div
        className={this.getClassNames()}
        onClick={(event) => this.handleOnClick(event)}
      >
        {this.props.value}
      </div>
    );
  }

  renderEmpty() {
    return <div className={this.getClassNames(["inplaceEmpty"])}></div>;
  }

  renderDivWithTranslation() {
    const translation = this.props.options.filter(
      (option) => option.key !== undefined && option.key === this.props.value
    );
    if (!translation || translation.length === 0) return this.renderDiv();
    return (
      <div
        className={this.getClassNames()}
        onClick={(event) => this.handleOnClick(event)}
      >
        {translation[0].value}
      </div>
    );
  }

  renderInputText() {
    return (
      <input
        type="text"
        placeholder={this.props.placeholder}
        className={this.getClassNames()}
        value={this.props.value}
        onChange={(event) => this.handleOnChange(event)}
        onBlur={(event) => this.handleOnBlur(event)}
        autoFocus={this.state.localEditable}
      />
    );
  }

  renderInputTextWithList() {
    const randomId = "dl_" + Math.random();
    return (
      <>
        <input
          type="text"
          list={randomId}
          placeholder={this.props.placeholder}
          className={this.getClassNames()}
          value={this.props.value}
          onChange={(event) => this.handleOnChange(event)}
          onBlur={(event) => this.handleOnBlur(event)}
          autoFocus={this.state.localEditable}
        />
        <datalist id={randomId}>
          {this.props.options.map((option) => (
            <option
              key={"option_" + this.parseOption(option).key}
              value={this.parseOption(option).key}
            >
              {this.parseOption(option).value}
            </option>
          ))}
        </datalist>
      </>
    );
  }

  renderTextArea() {
    return (
      <textarea
        className={this.getClassNames()}
        onChange={(event) => this.handleOnChange(event)}
        onBlur={(event) => this.handleOnBlur(event)}
        autoFocus={this.state.localEditable}
        value={this.props.value}
      ></textarea>
    );
  }

  renderInputSelect() {
    return (
      <select
        className={this.getClassNames()}
        value={this.props.value}
        onChange={(event) => this.handleOnChange(event)}
        onBlur={(event) => this.handleOnBlur(event)}
        autoFocus={this.state.localEditable}
      >
        {this.props.options.map((option) => (
          <option
            key={"option_" + this.parseOption(option).key}
            value={this.parseOption(option).key}
          >
            {this.parseOption(option).value}
          </option>
        ))}
      </select>
    );
  }

  render() {
    if (!this.props.editable && !this.state.localEditable) {
      if (!this.props.options) {
        if (
          this.props.options === false &&
          (!this.props.value || this.props.value === "")
        ) {
          return this.renderEmpty();
        } else {
          return this.renderDiv();
        }
      } else {
        return this.renderDivWithTranslation();
      }
    } else if (this.props.multiLine) {
      return this.renderTextArea();
    } else if (
      this.props.options === null ||
      this.props.options === undefined
    ) {
      return this.renderInputText();
    } else if (this.props.options === false) {
      if (!this.props.value || this.props.value === "") {
        return this.renderEmpty();
      } else {
        return this.renderDiv();
      }
    } else if (this.props.strict) {
      return this.renderInputSelect();
    } else {
      return this.renderInputTextWithList();
    }
  }
}
