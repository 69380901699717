import React from "react";
import Card from "./endpoints/card.js";
import { Accordion, AccordionTab } from "./components/accordion.js";
import { Responsive, WidthProvider } from "react-grid-layout";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import "@polymer/paper-button/paper-button.js";
import debounce from "lodash.debounce";
import _ from "lodash";

const defaultMeta = {
  attributes: {
    required: [],
    supported: [],
    allowMultiple: true,
  },
  cardConfig: {
    minW: 2,
    minH: 1,
  },
};

const ResponsiveGridLayout = WidthProvider(Responsive);

export default class CardGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentBreakpoint: "lg",
      layouts: props.inputLayouts,
    };
    this.currentCardsMetas = {};
    this.cardDivs = React.createRef();
    this.currentlayouts = { ...props.inputLayouts };
    this.layoutCols = { lg: 14, md: 12, sm: 10, xs: 6, xxs: 4 };
    this.debouncedOnCardsLayoutUpdate = debounce(
      (breakpoint, layout) => this.onCardsLayoutUpdate(breakpoint, layout),
      500
    );
    let expectedLength = Object.keys(this.props.cards).length;
    if (
      !this.state.layouts[this.state.currentBreakpoint] ||
      !this.state.layouts[this.state.currentBreakpoint].length ||
      this.state.layouts[this.state.currentBreakpoint].length !== expectedLength
    ) {
      if (Object.keys(this.state.layouts).length) {
        let filteredLayouts = Object.fromEntries(
          Object.entries(this.state.layouts).filter(
            ([key, layout]) => layout.length === expectedLength
          )
        );
        if (Object.keys(filteredLayouts).length) {
          this.rebuildLayout(
            this.state.currentBreakpoint,
            Object.keys(filteredLayouts)[0],
            true
          );
        } else {
          this.rebuildLayout(
            this.state.currentBreakpoint,
            Object.keys(this.state.layouts)[0],
            true
          );
        }
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    let expectedLength = Object.keys(nextProps.cards).length;
    if (
      JSON.stringify(this.props.inputLayouts) !=
      JSON.stringify(nextProps.inputLayouts)
    ) {
      this.setState({
        layouts: nextProps.inputLayouts,
      });
      this.currentlayouts = { ...nextProps.inputLayouts };
      console.log("loaded new input layouts", nextProps.inputLayouts);
      if (
        !this.currentlayouts[this.state.currentBreakpoint] ||
        !this.currentlayouts[this.state.currentBreakpoint].length ||
        this.currentlayouts[this.state.currentBreakpoint].length !==
          expectedLength
      ) {
        if (Object.keys(this.currentlayouts).length) {
          let filteredLayouts = Object.fromEntries(
            Object.entries(this.currentlayouts).filter(
              ([key, layout]) => layout.length === expectedLength
            )
          );
          if (Object.keys(filteredLayouts).length) {
            this.rebuildLayout(
              this.state.currentBreakpoint,
              Object.keys(filteredLayouts)[0],
              false
            );
          } else {
            this.rebuildLayout(
              this.state.currentBreakpoint,
              Object.keys(this.currentlayouts)[0],
              false
            );
          }
        }
      }
    }
    if (
      this.currentlayouts[this.state.currentBreakpoint].length !==
      expectedLength
    ) {
      console.log(
        "preupdate, current(",
        this.state.currentBreakpoint,
        "):",
        this.currentlayouts[this.state.currentBreakpoint].length,
        " expected:",
        expectedLength
      );
      console.log("wtf", this.currentlayouts[this.state.currentBreakpoint]);
      this.updateLayout(
        this.state.currentBreakpoint,
        nextProps.cards,
        this.currentCardsMetas,
        nextProps.locked
      );
    }
    if (this.props.locked !== nextProps.locked) {
      this.handleLockChange(nextProps.locked);
    }
    return true;
  }

  handleMetaUpdate(cardId, meta) {
    console.log("handleMetaUpdate for: ", cardId, "meta: ", meta);
    this.currentCardsMetas[cardId] = meta;
    this.props.handleMetaUpdate(cardId, meta);
  }

  calcSpaceCost(x, y, cols) {
    return x + y * cols;
  }

  rebuildLayout(targetBp, sourceBp, startup = false) {
    console.log("rebuilding bp", targetBp, "from", sourceBp);
    let sourceLayout = this.currentlayouts[sourceBp].sort((a, b) =>
      a.y - b.y ? a.y - b.y : a.x - b.x
    );
    var targetLayout = [];
    let targetCols = this.layoutCols[targetBp];
    for (let it in sourceLayout) {
      let cardLayout = sourceLayout[it];

      var newCardLayout = {
        i: cardLayout.i,
        w: cardLayout.w,
        h: cardLayout.h,
        minW: cardLayout.minW,
        minH: cardLayout.minH,
        static: cardLayout.static,
      };
      let space = this.findSpace(
        cardLayout.w,
        cardLayout.h,
        targetLayout,
        targetCols,
        this.calcSpaceCost(
          cardLayout.x,
          cardLayout.y,
          this.layoutCols[sourceBp]
        )
      );
      newCardLayout.x = space.x;
      newCardLayout.y = space.y;
      targetLayout.push(newCardLayout);
    }
    console.log("rebuilt layout", targetLayout);
    if (startup) {
      this.currentlayouts = { ...this.state.layouts, [targetBp]: targetLayout };
      this.state.layouts = { ...this.state.layouts, [targetBp]: targetLayout };
    } else {
      this.currentlayouts = {
        ...this.currentlayouts,
        [targetBp]: targetLayout,
      };
      this.setState({
        layouts: { ...this.currentlayouts, [targetBp]: targetLayout },
      });
    }
  }

  updateLayout(bp, cards, cardMetas, locked) {
    if (!cardMetas || cardMetas.length === 0) {
      console.log("skip updating bp", bp, "for", cards, "metas", cardMetas);
      return;
    }
    console.log("updating bp", bp, "for", cards, "metas", cardMetas);
    let toBeRemoved = Object.values(this.currentlayouts[bp] ?? {})
      .filter(
        (cardLayout) =>
          undefined ===
          Object.keys(cards).find(
            (cardId) => cardLayout.i === "cardDiv_" + cardId
          )
      )
      .map((cardLayout) => cardLayout.i);
    console.log("toBeRemoved", toBeRemoved);
    let layouts = _.mapValues(this.currentlayouts, (layout) =>
      _.reject(layout, (cardLayout) => toBeRemoved.includes(cardLayout.i))
    );
    var targetLayout = JSON.parse(JSON.stringify(layouts[bp] ?? []));
    let targetCols = this.layoutCols[bp];

    for (let it in cards) {
      let sourceCard = cards[it];
      let cardMeta = cardMetas[it];
      console.log("updating, cardMeta for", it, ":", cardMeta);
      let cardId = "cardDiv_" + sourceCard.id;
      let targetFind = Object.values(targetLayout).filter(
        (targetCardLayout) => targetCardLayout.i === cardId
      );
      if (targetFind.length && cardMeta) {
        //if exists, update only minH and minW
        targetFind[0].minH = cardMeta.cardConfig.minH;
        targetFind[0].minW = cardMeta.cardConfig.minW;
        targetFind[0].w = Math.max(cardMeta.cardConfig.minW, targetFind[0].w);
        targetFind[0].h = Math.max(cardMeta.cardConfig.minH, targetFind[0].h);
      } else if (!targetFind.length) {
        //else insert new cardLayout
        if (!cardMeta) cardMeta = defaultMeta;
        var newCardLayout = {
          i: cardId,
          w: cardMeta.cardConfig.minW,
          h: cardMeta.cardConfig.minH,
          minW: cardMeta.cardConfig.minW,
          minH: cardMeta.cardConfig.minH,
          static: locked,
        };
        let space = this.findSpace(
          newCardLayout.w,
          newCardLayout.h,
          targetLayout,
          targetCols
        );
        newCardLayout.x = space.x;
        newCardLayout.y = space.y;
        targetLayout.push(newCardLayout);
      }
    }
    console.log("updated layout", targetLayout);

    this.currentlayouts = { ...this.currentlayouts, [bp]: targetLayout };
    this.setState({
      layouts: { ...this.currentlayouts, [bp]: targetLayout },
    });
  }

  onCardsLayoutUpdate(breakpoint, layout) {
    //TODO: add id to cardGrid!
    fetch(window.serverAddr + "/api/request/cardsLayoutUpdate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": window.token,
      },
      body: JSON.stringify({
        gridId: this.props.gridId,
        breakpoint: breakpoint,
        layout: layout,
      }),
      mode: "cors",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("onCardsLayoutUpdate", result);
      });
  }

  onBreakpointChange(breakpoint) {
    this.setState({
      currentBreakpoint: breakpoint,
    });
    console.log("onBreakpointChange");
    let expectedLength = Object.keys(this.props.cards).length;
    if (
      !this.state.layouts[breakpoint] ||
      !this.state.layouts[breakpoint].length ||
      this.state.layouts[breakpoint].length !== expectedLength
    ) {
      if (Object.keys(this.state.layouts).length) {
        let filteredLayouts = Object.fromEntries(
          Object.entries(this.state.layouts).filter(
            ([key, layout]) => layout.length === expectedLength
          )
        );
        if (Object.keys(filteredLayouts).length) {
          this.rebuildLayout(breakpoint, Object.keys(filteredLayouts)[0]);
        } else {
          this.rebuildLayout(breakpoint, Object.keys(this.state.layouts)[0]);
        }
      }
    }
  }

  onLayoutChange(layout, layouts) {
    if (
      JSON.stringify(this.currentlayouts) !== JSON.stringify(this.state.layouts)
    ) {
      console.log("onLayoutChange detected other change in progress, skip");
      return;
    }

    this.setState({
      layouts: layouts,
    });
    let oldLayout = this.currentlayouts[this.state.currentBreakpoint];
    let newLayoutFiltered = layout.filter((value) => oldLayout[value.i]);
    console.log("newLayoutFiltered: ", newLayoutFiltered);
    console.log("this.currentCardsMetas: ", this.currentCardsMetas);
    for (let cardLayoutId in layout) {
      let newCardLayout = layout[cardLayoutId];
      let oldCardLayout = oldLayout[cardLayoutId];
      let cardId = newCardLayout.i.substr("cardDiv_".length);
      let cardMeta = this.currentCardsMetas[cardId];
      if (!oldCardLayout) {
        /*new card or missing old layout*/
        let space = this.findSpace(
          newCardLayout.w,
          newCardLayout.h,
          newLayoutFiltered
        );
        newCardLayout.x = space.x;
        newCardLayout.y = space.y;

        if (cardMeta) {
          newCardLayout.w = cardMeta.cardConfig.minW;
          newCardLayout.h = cardMeta.cardConfig.minH;
          newCardLayout.minW = cardMeta.cardConfig.minW;
          newCardLayout.minH = cardMeta.cardConfig.minH;
        }
        newCardLayout.static = this.props.locked;

        console.log("found new card: ", newCardLayout, "new space: ", space);
        newLayoutFiltered.push(newCardLayout);
      } else if (
        JSON.stringify(newCardLayout) !== JSON.stringify(oldCardLayout)
      ) {
        /* card changed */
      }
    }
    this.currentlayouts = layouts;
    console.log("onLayoutChange", this.currentlayouts);
    this.debouncedOnCardsLayoutUpdate(
      this.state.currentBreakpoint,
      this.currentlayouts[this.state.currentBreakpoint]
    );
  }

  handleCardConfigUpdate(cardId, cardConfig) {
    if (
      this.cardDivs.current &&
      this.cardDivs.current.elementRef &&
      this.cardDivs.current.elementRef.current &&
      this.cardDivs.current.elementRef.current.children
    ) {
      let currentLayout = this.currentlayouts[this.state.currentBreakpoint];
      var newLayout = JSON.parse(JSON.stringify(currentLayout));

      let cardLayout = currentLayout
        .map((obj, index) => (obj.i === "cardDiv_" + cardId ? index : null))
        .filter((index) => index !== null);

      if (!cardLayout || !cardLayout.length) {
        //new card
        var newCardLayout = {
          i: "cardDiv_" + cardId,
          x: cardConfig.x ? cardConfig.x : 0,
          y: 100,
          w: cardConfig.minW,
          h: cardConfig.minH,
          minW: cardConfig.minW,
          minH: cardConfig.minH,
          static: cardConfig.static,
        };
        newLayout.push(newCardLayout);
      } else {
        newLayout[cardLayout[0]].minW = cardConfig.minW;
        newLayout[cardLayout[0]].minH = cardConfig.minH;
        newLayout[cardLayout[0]].w = Math.max(
          newLayout[cardLayout[0]].w,
          cardConfig.minW
        );
        newLayout[cardLayout[0]].h = Math.max(
          newLayout[cardLayout[0]].h,
          cardConfig.minH
        );
      }

      this.setState({
        layouts: {
          ...this.currentlayouts,
          [this.state.currentBreakpoint]: newLayout,
        },
      });
      this.currentlayouts = {
        ...this.currentlayouts,
        [this.state.currentBreakpoint]: newLayout,
      };
    }
    this.debouncedOnCardsLayoutUpdate(
      this.state.currentBreakpoint,
      this.currentlayouts[this.state.currentBreakpoint]
    );
  }

  handleLockChange(locked) {
    var newLayouts = {};
    for (let breakpoint in this.currentlayouts) {
      let currentLayout = this.currentlayouts[breakpoint];
      var newLayout = JSON.parse(JSON.stringify(currentLayout));
      for (let cardIndex in currentLayout) {
        newLayout[cardIndex].static = locked;
      }
      newLayouts[breakpoint] = newLayout;
    }

    this.setState({
      layouts: { ...newLayouts },
    });
    this.currentlayouts = { ...newLayouts };
  }

  findSpace(
    width,
    height = 1,
    layout = this.state.layouts[this.state.currentBreakpoint],
    cols = this.layoutCols[this.state.currentBreakpoint],
    targetCost = 0
  ) {
    var x = 0;
    var y = 100;
    var lastCost = 10000;
    for (let tx = 0; tx <= cols - width; tx++) {
      var ty = 0;
      var cost = tx + 1;
      for (let ox = 0; ox < width; ox++) {
        for (let it in layout) {
          let item = layout[it];
          if (item.x <= tx + ox && item.x + item.w - 1 >= tx + ox) {
            ty = item.y + item.h;
            let newCost = this.calcSpaceCost(tx, ty, cols);
            //(tx > 4 ? tx / 2 : 0) + (ty + (height - 1)) * (cols / 3 + 1);
            cost = Math.max(cost, newCost);
          }
        }
      }
      if (Math.abs(cost - targetCost) < lastCost) {
        x = tx;
        y = ty;
        lastCost = Math.abs(cost - targetCost);
      }
    }
    console.log("found space", x, y, lastCost, "(-> ", targetCost, ")");

    return { x: x, y: y };
  }

  render() {
    return (
      <ResponsiveGridLayout
        ref={this.cardDivs}
        id="cards"
        className={"layout " + this.props.className ?? ""}
        layouts={this.state.layouts}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={this.layoutCols}
        onBreakpointChange={(breakpoint) => this.onBreakpointChange(breakpoint)}
        onLayoutChange={(layout, layouts) =>
          this.onLayoutChange(layout, layouts)
        }
      >
        {Object.keys(this.props.cards).map((key) => {
          return (
            <div id={"cardDiv_" + key} key={"cardDiv_" + key}>
              <Card
                key={"card_" + key}
                detailsMode={false}
                cardId={key}
                card={this.props.cards[key]}
                locked={this.props.locked}
                allEndpoints={this.props.endpoints}
                handleMetaUpdate={(cardId, meta) =>
                  this.handleMetaUpdate(cardId, meta)
                }
                handleDialogCardEditOpen={(cardId, metaRef) =>
                  this.props.handleDialogCardEditOpen(cardId, metaRef)
                }
                handleCardConfigUpdate={(cardId, cardConfig) =>
                  this.handleCardConfigUpdate(cardId, cardConfig)
                }
                handleDefaultOnClick={(cardId) =>
                  this.props.handleDefaultOnClick(cardId)
                }
                handleLoadData={(endpointIds, meta, period) =>
                  this.props.handleLoadData(endpointIds, meta, period)
                }
              />
            </div>
          );
        })}
      </ResponsiveGridLayout>
    );
  }
}
