import React from "react";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import { importsEndpoints } from "./endpoints/_imports.js";
import "@polymer/paper-button/paper-button.js";
import "@polymer/paper-dialog/paper-dialog.js";
import "@polymer/paper-dialog-scrollable/paper-dialog-scrollable.js";
import _ from "lodash";
import Card from "./endpoints/card.js";
import { Tabs, Tab } from "./components/tabs.js";
import CardRulesSubdialog from "./cardRulesSubdialog.js";

export default class CardDetailsDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.cardDetailsDialog = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.open !== nextProps.open) {
      if (nextProps.open) {
        this.cardDetailsDialog.current.open();
        this.setState({ detailsPeriod: "raw", detailsTab: "details" }); //TODO: change default period to "hour" (After aggregation is done)
      } else this.cardDetailsDialog.current.close();
    }
    return true;
  }

  componentDidMount() {
    this.cardDetailsDialog.current.addEventListener(
      "iron-overlay-closed",
      () => {
        this.props.onClose();
      }
    );
  }

  render() {
    return (
      <paper-dialog id="dialogCardDetails" ref={this.cardDetailsDialog}>
        <div className="dialogContent80">
          <Tabs
            items={[
              { label: "Details", state: "details" },
              { label: "Rules", state: "rules" },
            ]}
            setState={(state) => {
              this.setState({ detailsTab: state });
            }}
            visibleTab={this.state.detailsTab}
          />

          <div className="dialogContentStretch">
            <Tab title="Details" visible={this.state.detailsTab === "details"}>
              <div className="scroll">
                <div className="dialogContentFill">
                  <Tabs
                    items={[
                      { label: "Raw", state: "raw" },
                      { label: "Hour", state: "hour" },
                      { label: "Day", state: "day" },
                      { label: "Week", state: "week" },
                      { label: "Month", state: "month" },
                    ]}
                    setState={(state) => {
                      this.setState({ detailsPeriod: state });
                    }}
                    visibleTab={this.state.detailsPeriod}
                  />
                  <Card
                    detailsMode={true}
                    detailsPeriod={this.state.detailsPeriod}
                    cardId={this.props.cardId}
                    card={this.props.cards[this.props.cardId]}
                    allEndpoints={this.props.endpoints}
                    handleMetaUpdate={(cardId, meta) => {}}
                    handleDialogCardEditOpen={(cardId, metaRef) => {}}
                    handleCardConfigUpdate={(cardId, cardConfig) => {}}
                    handleDefaultOnClick={(cardId) => {}}
                    handleLoadData={(endpointIds, meta, period) =>
                      this.props.handleLoadData(endpointIds, meta, period)
                    }
                  />
                </div>
              </div>
            </Tab>
            <Tab title="Rules" visible={this.state.detailsTab === "rules"}>
              <paper-dialog-scrollable>
                <CardRulesSubdialog
                  card={this.props.cards[this.props.cardId]}
                  allEndpoints={this.props.endpoints}
                />
              </paper-dialog-scrollable>
            </Tab>
          </div>
          <div className="dialogContentButtons">
            <paper-button
              raised
              class="button"
              onClick={() => {
                this.props.handleClose();
              }}
            >
              Ok
            </paper-button>
          </div>
        </div>
      </paper-dialog>
    );
  }
}
