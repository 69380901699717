import React from "react";
import "./accordion.css";
import "@polymer/paper-button/paper-button.js";

export class Accordion extends React.Component {
  render() {
    return (
      <div
        className={
          "accordion " + (this.props.className ? this.props.className : "")
        }
      >
        <form>{this.props.children}</form>
      </div>
    );
  }
}

export class AccordionTab extends React.Component {
  render() {
    return (
      <div className="accordionItem">
        <input
          id={"accordionTitle_" + this.props.title}
          name="accordion"
          type="radio"
          defaultChecked={this.props.defaultChecked}
        />
        <label htmlFor={"accordionTitle_" + this.props.title}>
          {this.props.title}
        </label>
        <div className="accordionInternal">{this.props.children}</div>
      </div>
    );
  }
}
