import React from "react";
import { InplaceEdit } from "../components/inplaceEdit.js";
import { parseTimestamp } from "../parseTimestamp.js";
import Endpoint from "./endpoint.js";
import "@polymer/paper-button/paper-button.js";

const meta = {
  attributes: {
    required: ["Attribute"],
    supported: [],
    allowMultiple: true,
    leftOvers: true,
  },
  cardConfig: {
    minW: 1,
    minH: 1,
  },
  detailsConfig: {
    dataAggregation: [
      { fieldByCapabilityType: "Attribute", aggregation: "med" },
    ],
  },
  defaultLocations: ["Bedroom", "Living room", "Kitchen", "Bathroom"],
};

export default class EndpointDevice extends Endpoint {
  getMeta() {
    return meta;
  }
  static getStaticMeta() {
    return meta;
  }

  constructor(props) {
    super(props);
    this.contextEndpointDevice = this;
    this.meta = meta;
    this.state = {
      markForDelete: false,
    };
  }

  handleRemove() {
    let endpointAddr = this.props.endpoints[0].endpointAddr;
    if (!this.state.markForDelete) {
      this.setState({
        markForDelete: true,
      });
    } else {
      console.log("delete: " + endpointAddr);
      fetch(window.serverAddr + "/api/request/remove", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": window.token,
        },
        body: JSON.stringify({
          devAddr: endpointAddr,
          execute: true,
        }),
        mode: "cors",
        credentials: "include",
      })
        .then((res) => res.json())
        .then((result) => {
          console.log("handleRemove", result);
        });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.locked !== nextProps.locked) {
      this.setState({
        markForDelete: false,
      });
    }
    return true;
  }

  renderCard() {
    let endpoints = this.props.endpoints;
    var batt =
      this.props.endpoints
        .filter((endpoint) => endpoint)
        .reduce((arr, endpoint) => arr.concat(endpoint.capabilities), [])
        .filter(
          (attribute) =>
            attribute.values &&
            attribute.capabilityType.includes("AttributeBattery")
        )
        .reduce(
          (prev, curr) =>
            (prev ?? curr.values[0]) < curr.values[0] ? prev : curr.values[0],
          null
        ) ?? 0;
    var battPercent =
      this.props.endpoints
        .filter((endpoint) => endpoint)
        .reduce((arr, endpoint) => arr.concat(endpoint.capabilities), [])
        .filter(
          (attribute) =>
            attribute.values &&
            attribute.capabilityType.includes("AttributeBattery")
        )
        .reduce(
          (prev, curr) =>
            (prev ?? curr.valuesPercent[0]) < curr.valuesPercent[0]
              ? prev
              : curr.valuesPercent[0],
          null
        ) ?? 1.0;
    var lqi =
      this.props.endpoints
        .filter((endpoint) => endpoint)
        .reduce((arr, endpoint) => arr.concat(endpoint.capabilities), [])
        .filter(
          (attribute) =>
            attribute.values &&
            attribute.capabilityType.includes("AttributeLQI")
        )
        .reduce(
          (prev, curr) =>
            (prev ?? curr.values[0]) < curr.values[0] ? prev : curr.values[0],
          null
        ) ?? 0;
    var lqiPercent =
      this.props.endpoints
        .filter((endpoint) => endpoint)
        .reduce((arr, endpoint) => arr.concat(endpoint.capabilities), [])
        .filter(
          (attribute) =>
            attribute.values &&
            attribute.capabilityType.includes("AttributeLQI")
        )
        .reduce(
          (prev, curr) =>
            (prev ?? curr.valuesPercent[0]) < curr.valuesPercent[0]
              ? prev
              : curr.valuesPercent[0],
          null
        ) ?? 0;
    var online =
      this.props.endpoints
        .filter((endpoint) => endpoint)
        .reduce((prev, curr) => prev && curr.online, true) ?? false;
    if (!online) lqiPercent = 0;

    return (
      <>
        <td className="cardNameUnlimited shrink">
          {this.getName(this.props.endpoints[0])}
        </td>
        <td className="endpointIconSmall shrink">
          {this.props.endpoints
            .filter((endpoint) => endpoint)
            .map((endpoint, index) => (
              <img
                key={"icon_" + index}
                src={
                  process.env.PUBLIC_URL +
                  "/endpointType_" +
                  endpoint.endpointTypeName +
                  ".png"
                }
                alt="endpoint icon"
              />
            ))}
        </td>
        <td className={this.state.markForDelete ? "markForDelete" : ""}>
          {this.props.locked ? (
            <paper-button raised disabled class="button" onClick={() => {}}>
              R
            </paper-button>
          ) : (
            <paper-button
              raised
              class="button"
              onClick={() => this.handleRemove()}
            >
              R
            </paper-button>
          )}
        </td>
        <td className="expand">
          <div className="endpointLocationColumn">
            <InplaceEdit
              className="fillWidth"
              editable={true}
              strict={false}
              options={this.props.locations ?? meta.defaultLocations}
              value={
                this.props.endpoints[0]
                  ? this.props.endpoints[0].location ?? ""
                  : ""
              }
              placeholder="Location"
              onChange={(value, invalid) =>
                this.props.handleLocationChange(
                  this.props.endpoints[0].endpointAddr,
                  value
                )
              }
            />
          </div>
        </td>
        <td className="shrink">
          <div className="endpointDataColumn">
            <div className="endpointDataClip">
              {endpoints.map((endpoint) => this.renderBottomEndpoint(endpoint))}
              <div className="dataItemClear"></div>
            </div>
          </div>
        </td>
        <td className="shrink">
          <div
            className={
              "dataItemValueTiny lqi lqiValueTiny " +
              ("lqiValueTiny_" + parseInt(Math.ceil((lqiPercent * 100) / 20.0)))
            }
          >
            {lqi}
          </div>
        </td>
        <td className="shrink">
          <div
            className={
              "dataItemValueTiny batt battValueTiny " +
              ("battValueTiny_" +
                parseInt(Math.round((battPercent * 100) / 20.0)))
            }
          >
            {batt}
          </div>
        </td>
      </>
    );
  }

  renderBottomEndpoint(endpointData) {
    const endpointName = this.getName(endpointData);
    let attributes = endpointData.capabilities.filter(
      (attribute) =>
        attribute.values && attribute.capabilityType.includes("Attribute")
    );
    attributes = attributes.filter(
      (attribute) => !attribute.capabilityType.includes("AttributeLQI")
    );
    attributes = attributes.filter(
      (attribute) => !attribute.capabilityType.includes("AttributeBattery")
    );
    return (
      <div key={"bottomDataEndpointNameDiv_" + endpointName}>
        {attributes.map((attribute) =>
          this.renderBottomData(endpointName, attribute)
        )}
        <div
          key={"bottomDataEndpointLastUpdateName_" + endpointName}
          className="dataEndpointLastUpdate dataItemNameTiny"
        >
          Last Update:
        </div>
        <div
          key={"bottomDataEndpointLastUpdate_" + endpointName}
          className="dataEndpointLastUpdate dataItemValueTiny timestamp"
        >
          {parseTimestamp(endpointData.timestamps[0], "YYYY-MM-DD HH:mm:ss") ??
            "?"}
        </div>
      </div>
    );
  }

  prepareAttributesForChart(meta, endpointData) {
    let fields = meta.detailsConfig.dataAggregation
      .reduce((prev, curr) => prev.concat(curr), [])
      .map((field) => field.fieldByCapabilityType);
    var capabilities;
    var timestamps;
    if ("raw" === this.props.detailsPeriod) {
      capabilities = endpointData.capabilities;
      timestamps = endpointData.timestamps;
    } else {
      capabilities =
        endpointData["capabilities_" + this.props.detailsPeriod] ?? [];
      timestamps = endpointData["timestamps_" + this.props.detailsPeriod] ?? [];
    }

    var attributes = capabilities.filter((attribute) =>
      attribute.capabilityType.some((r) => fields.includes(r))
    );
    attributes = attributes.filter(
      (attribute) => !attribute.valueClass.split(" ").includes("timestamp")
    );
    attributes = attributes.filter(
      (attribute) => attribute.displayOnChart !== false
    );

    return [timestamps, attributes];
  }
}
