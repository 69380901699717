import React from "react";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import "@polymer/paper-button/paper-button.js";
import "@polymer/paper-dialog/paper-dialog.js";
import _ from "lodash";

export default class LoginDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: null,
      password: null,
      disabledForm: false,
    };
    this.loginDialog = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.open !== nextProps.open) {
      if (nextProps.open) this.loginDialog.current.open();
      else this.loginDialog.current.close();
    }
    return true;
  }

  componentDidMount() {
    this.loginDialog.current.addEventListener("iron-overlay-closed", () => {
      this.props.onClose();
    });
  }

  setUsername(username) {
    this.setState({
      username: username,
    });
  }

  setPassword(password) {
    this.setState({
      password: password,
    });
  }

  getCookie(name) {
    if (!document.cookie) {
      return null;
    }

    const xsrfCookies = document.cookie
      .split(";")
      .map((c) => c.trim())
      .filter((c) => c.startsWith(name + "="));

    if (xsrfCookies.length === 0) {
      return null;
    }
    return decodeURIComponent(xsrfCookies[0].split("=")[1]);
  }

  handleLogin() {
    this.setState({ disabledForm: true });
    fetch(window.serverAddr + "/api/session/login", {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": window.token,
      },
      body: JSON.stringify({
        username: this.state.username,
        password: this.state.password,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("login", result.status);
        this.props.handleLoginStatus("finished", result.status);
        let newToken = result.token;
        console.log("old token", window.token);
        console.log("update token", newToken);
        window.token = newToken;
        this.setState({ disabledForm: false });
        if (result.status) {
          this.props.handleClose();
        } else {
          alert("wrong username/password");
        }
      });
    this.props.handleLoginStatus("sent", null);
  }

  handleLogout() {
    this.setState({ disabledForm: true });
    fetch(window.serverAddr + "/api/session/logout", {
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": window.token,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("logout", result);
        this.props.handleLoginStatus("logout", result);
        let newToken = this.getCookie("csrftoken");
        console.log("update token", newToken);
        window.token = newToken;
        this.setState({ disabledForm: false });
        this.props.handleClose();
      });
    this.props.handleLoginStatus("logoutSent", null);
  }

  render() {
    if (!this.props.username) {
      return (
        <paper-dialog id="loginDialog" ref={this.loginDialog}>
          <h2>Login</h2>

          <div className="login">
            <div>
              <input
                type="text"
                name="username"
                disabled={this.state.disabledForm}
                placeholder="username"
                onChange={(event) => this.setUsername(event.target.value)}
                onKeyDown={(ev) => {
                  if (!this.state.disabledForm && ev.key === "Enter")
                    this.handleLogin();
                }}
              />
            </div>
            <div>
              <input
                type="password"
                name="password"
                disabled={this.state.disabledForm}
                placeholder="password"
                onChange={(event) => this.setPassword(event.target.value)}
                onKeyDown={(ev) => {
                  if (!this.state.disabledForm && ev.key === "Enter")
                    this.handleLogin();
                }}
              />
            </div>
          </div>
          {this.state.disabledForm ? (
            <>
              <paper-button
                raised
                disabled
                class="button"
                onClick={() => {
                  this.handleLogin();
                }}
              >
                Login
              </paper-button>
              <paper-button
                raised
                disabled
                class="button"
                onClick={() => {
                  this.props.handleClose();
                }}
              >
                Close
              </paper-button>
            </>
          ) : (
            <>
              <paper-button
                raised
                class="button"
                onClick={() => {
                  this.handleLogin();
                }}
              >
                Login
              </paper-button>
              <paper-button
                raised
                class="button"
                onClick={() => {
                  this.props.handleClose();
                }}
              >
                Close
              </paper-button>
            </>
          )}
        </paper-dialog>
      );
    } else {
      return (
        <paper-dialog id="loginDialog" ref={this.loginDialog}>
          <h2>{this.props.username}</h2>

          <div className="logout">
            <div></div>
          </div>

          <paper-button
            raised
            class="button"
            onClick={() => {
              this.handleLogout();
            }}
          >
            Logout
          </paper-button>
          <paper-button
            raised
            class="button"
            onClick={() => {
              this.props.handleClose();
            }}
          >
            Close
          </paper-button>
        </paper-dialog>
      );
    }
  }
}
