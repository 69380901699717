import React from "react";
import "@polymer/paper-button/paper-button.js";
import FlashChange from "@avinlab/react-flash-change";

import { lazy, Suspense } from "react";
import { importsEndpoints } from "./_imports.js";

export var Components = {};
for (let importName in importsEndpoints) {
  Components[importName] = lazy(() =>
    import("./" + importsEndpoints[importName].module)
  );
}

const defaultMeta = {
  attributes: {
    required: [],
    supported: [],
    allowMultiple: true,
  },
  cardConfig: {
    minW: 2,
    minH: 1,
  },
};

export default class Card extends React.PureComponent {
  constructor(props) {
    super(props);
    this.contextCard = this;
    this.allEndpoints = props.allEndpoints;
    this.card = props.card;
    this.endpoints = [];
    this.state = {
      cardId: props.cardId,
      mounted: false,
      cardTypeName: null,
      meta: defaultMeta,
    };
    this.internalObj = React.createRef();
    this.endpointObj = React.createRef();
    this.width = 0;
    this.height = 0;
  }

  componentDidMount() {
    this.setState({
      mounted: true,
    });
    let newMeta = this.internalObj.current
      ? this.internalObj.current.meta
      : defaultMeta;
    this.setState({
      meta: newMeta,
    });
    this.props.handleMetaUpdate(this.state.cardId, newMeta);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.card) return true;
    let newMeta = this.internalObj.current
      ? this.internalObj.current.meta
      : defaultMeta;
    if (
      prevState.cardTypeName !== this.card.typeName ||
      prevState.meta !== newMeta
    ) {
      this.setState({
        cardTypeName: this.card.typeName,
        meta: newMeta,
      });
      this.props.handleMetaUpdate(this.state.cardId, newMeta);
    }
  }

  render() {
    this.allEndpoints = this.props.allEndpoints;
    this.card = this.props.card;
    if (!this.card) {
      return <div></div>;
    }
    this.endpoints = this.card.endpointNames
      .map((endpointName) => {
        return this.allEndpoints[endpointName];
      })
      .filter((endpoint) => endpoint);
    if (!this.props.detailsMode) {
      return (
        <>
          <FlashChange
            key={"cardDiv_" + this.state.cardId}
            value={this.endpoints}
            className="cardDiv"
            flashClassName="changing"
            compare={(prevProps, nextProps) => {
              return (
                JSON.stringify(nextProps.value) !==
                JSON.stringify(prevProps.value)
              );
            }}
          >
            <paper-card
              alt={this.state.cardId}
              class="endpoint"
              ref={this.endpointObj}
            >
              <Suspense fallback={<div>Loading...</div>}>
                {React.createElement(
                  Object.keys(Components).includes(this.card.typeName)
                    ? Components[this.card.typeName]
                    : Components["Endpoint"],
                  {
                    ref: this.internalObj,
                    key: "cardInternal_" + this.state.cardId,
                    detailsMode: this.props.detailsMode,
                    endpoints: this.endpoints,
                    contextCard: this.contextCard,
                    locked: this.props.locked,
                    handleCardConfigUpdate: (cardConfig) =>
                      this.props.handleCardConfigUpdate(
                        this.state.cardId,
                        cardConfig
                      ),
                    handleDefaultOnClick: () =>
                      this.props.handleDefaultOnClick(this.state.cardId),
                    handleLoadData: (endpointIds, meta, period) =>
                      this.props.handleLoadData(endpointIds, meta, period),
                    handleDialogCardEditOpen: () =>
                      this.props.handleDialogCardEditOpen(
                        this.state.cardId,
                        this.state.meta
                      ),
                  }
                )}
              </Suspense>
            </paper-card>
          </FlashChange>
        </>
      );
    } else {
      return (
        <Suspense fallback={<div>Loading...</div>}>
          {React.createElement(
            Object.keys(Components).includes(this.card.typeName)
              ? Components[this.card.typeName]
              : Components["Endpoint"],
            {
              ref: this.internalObj,
              key: "cardInternal_" + this.state.cardId,
              detailsMode: this.props.detailsMode,
              detailsPeriod: this.props.detailsPeriod,
              endpoints: this.endpoints,
              contextCard: this.contextCard,
              handleCardConfigUpdate: (cardConfig) =>
                this.props.handleCardConfigUpdate(
                  this.state.cardId,
                  cardConfig
                ),
              handleDefaultOnClick: () =>
                this.props.handleDefaultOnClick(this.state.cardId),
              handleLoadData: (endpointIds, meta, period) =>
                this.props.handleLoadData(endpointIds, meta, period),
            }
          )}
        </Suspense>
      );
    }
  }
}
