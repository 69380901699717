import React from "react";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import { importsEndpoints } from "./endpoints/_imports.js";
import "@polymer/paper-button/paper-button.js";
import "@polymer/paper-dialog/paper-dialog.js";
import "@polymer/paper-dialog-scrollable/paper-dialog-scrollable.js";
import _ from "lodash";

const defaultMeta = {
  attributes: {
    required: [],
    supported: [],
    allowMultiple: true,
  },
  cardConfig: {
    minW: 2,
    minH: 1,
  },
};

export default class CardEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.cardEditDialog = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.open !== nextProps.open) {
      if (nextProps.open) this.cardEditDialog.current.open();
      else this.cardEditDialog.current.close();
    }
    return true;
  }

  componentDidMount() {
    this.cardEditDialog.current.addEventListener("iron-overlay-closed", () => {
      this.props.onClose();
    });
  }

  //TODO: move requireCapabilities up
  render() {
    let cardAttributes = (
      this.props.cardMetas[this.props.cardId]
        ? this.props.cardMetas[this.props.cardId]
        : defaultMeta
    ).attributes;
    var endpointList = Object.keys(this.props.endpoints);

    return (
      <paper-dialog id="dialogCardEdit" ref={this.cardEditDialog}>
        <h2>Edit card</h2>
        <div className="cardNameEdit">
          <input
            type="text"
            value={
              this.props.cards[this.props.cardId]
                ? this.props.cards[this.props.cardId].name
                : "unknown"
            }
            onChange={(event) =>
              this.props.handleDialogCardEditName(
                this.props.cardId,
                event.target.value
              )
            }
          />
        </div>
        <div className="cardTypeEdit">
          <select
            value={
              this.props.cards[this.props.cardId]
                ? this.props.cards[this.props.cardId].typeName
                : "Endpoint"
            }
            onChange={(event) =>
              this.props.handleDialogCardEditType(
                this.props.cardId,
                event.target.value
              )
            }
          >
            {Object.keys(importsEndpoints)
              .filter((typeName) => importsEndpoints[typeName].visible ?? true)
              .map((typeName) => (
                <option
                  key={"dialogCardEditTypeOption_" + typeName}
                  value={typeName}
                >
                  {importsEndpoints[typeName].description}
                </option>
              ))}
          </select>
        </div>
        <paper-dialog-scrollable>
          <div className="checkboxes">
            {
              /*cardAttributes.required.map((attributeType) =>
              endpointList.filter((endpointKey) => this.props.endpoints[endpointKey] !== null)
                                               .filter((endpointKey) => this.props.endpoints[endpointKey].capabilityTypes.includes(attributeType))
                                               .map((endpointKey) => */
              endpointList
                .filter(
                  (endpointKey) => this.props.endpoints[endpointKey] !== null
                )
                .filter((endpointKey) =>
                  cardAttributes.required.every((v) =>
                    this.props.endpoints[endpointKey].capabilityTypes.includes(
                      v
                    )
                  )
                )
                .map((endpointKey) => {
                  if (!this.props.cards[this.props.cardId])
                    console.log("card not found: ", this.props.cardId);
                  return (
                    <div key={"dialogCardEditDiv_" + endpointKey}>
                      <input
                        type="checkbox"
                        key={"dialogCardEditCheckbox_" + endpointKey}
                        id={
                          "dialogCardEditCheckbox_" +
                          this.props.cardId +
                          "_" +
                          endpointKey
                        }
                        value={endpointKey}
                        checked={
                          this.props.cards[this.props.cardId]
                            ? this.props.cards[
                                this.props.cardId
                              ].endpointNames.includes(endpointKey)
                            : false
                        }
                        onChange={() =>
                          this.props.handleDialogCardEditCheck(
                            this.props.cardId,
                            endpointKey
                          )
                        }
                      />
                      <label
                        htmlFor={
                          "dialogCardEditCheckbox_" +
                          this.props.cardId +
                          "_" +
                          endpointKey
                        }
                      >
                        {this.props.endpoints[endpointKey].mapperName
                          ? this.props.endpoints[endpointKey].mapperName +
                            "(" +
                            (this.props.endpoints[endpointKey].location &&
                            this.props.endpoints[endpointKey].location.length
                              ? this.props.endpoints[endpointKey].location
                              : this.props.endpoints[endpointKey].name) +
                            ")"
                          : this.props.endpoints[endpointKey].name}
                      </label>
                    </div>
                  );
                })
            }
          </div>
        </paper-dialog-scrollable>
        <paper-button
          raised
          class="button"
          onClick={() => {
            this.props.handleClose();
          }}
        >
          Ok
        </paper-button>
        <paper-button
          raised
          class="redButton rightButton"
          onClick={() => {
            this.props.handleRemoveCard(this.props.cardId);
            this.cardEditDialog.current.close();
          }}
        >
          Remove
        </paper-button>
      </paper-dialog>
    );
  }
}
