import React, { Fragment } from "react";
import "@polymer/paper-card/paper-card.js";
import "@polymer/paper-button/paper-button.js";
import { parseTimestamp } from "../parseTimestamp.js";
import { autoScaleTime } from "../parseTimestamp.js";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
import _ from "lodash";
import { scaleSymlog } from "d3-scale";

const meta = {
  attributes: {
    required: ["Attribute"],
    supported: [],
    allowMultiple: true,
    leftOvers: true,
  },
  cardConfig: {
    minW: 2,
    minH: 1,
  },
  detailsConfig: {
    dataAggregation: [
      { fieldByCapabilityType: "Attribute", aggregation: "med" },
    ],
  },
};

const colors = [
  "#e69373",
  "#20639B",
  "#3CAEA3",
  "#F6D55C",
  "#ED553B",
  "#F298D1",
  "#8278CC",
  "#64E05C",
  "#FFC19E",
];

export default class Endpoint extends React.Component {
  getMeta() {
    return meta;
  }
  static getStaticMeta() {
    return meta;
  }

  constructor(props) {
    super(props);
    this.contextCard = props.contextCard;
    this.contextEndpoint = this;
    this.meta = meta;
    this.state = {
      mounted: false,
      detailsDataLoaded: null,
    };
  }

  requestEndpointCommand(commandObj) {
    fetch(window.serverAddr + "/api/request/endpointCommand", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": window.token,
      },
      body: JSON.stringify({
        devAddr: commandObj.endpointAddress,
        commandName: commandObj.commandName,
        commandArgs: commandObj.arguments,
        execute: true,
      }),
      mode: "cors",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("requestEndpointCommand", result);
      });
  }

  handleClick() {
    console.log("handleClick: default");
    this.props.handleDefaultOnClick();
  }

  componentDidMount() {
    this.setState({
      mounted: true,
    });
    this.props.handleCardConfigUpdate(this.getMeta().cardConfig);
  }

  renderMiddle(endpoints) {
    if (endpoints.length === 0) {
      return this.renderEmpty();
    }
    return <div></div>;
  }

  renderBottomEndpoint(endpointData) {
    const endpointName = this.getName(endpointData);
    let attributes = endpointData.capabilities.filter(
      (attribute) =>
        attribute.values && attribute.capabilityType.includes("Attribute")
    );
    attributes = attributes.filter(
      (attribute) => !attribute.capabilityType.includes("AttributeLQI")
    );
    attributes = attributes.filter(
      (attribute) => !attribute.capabilityType.includes("AttributeBattery")
    );
    return (
      <div key={"bottomDataEndpointNameDiv_" + endpointName}>
        <div
          key={"bottomDataEndpointName_" + endpointName}
          className="dataEndpointName dataItemNameTiny"
        >
          {endpointName}:&nbsp;
        </div>
        {attributes.map((attribute) =>
          this.renderBottomData(endpointName, attribute)
        )}
        <div
          key={"bottomDataClear1_" + endpointName}
          className="dataItemClear"
        ></div>
        <div
          key={"bottomDataEndpointLastUpdateName_" + endpointName}
          className="dataEndpointLastUpdate dataItemNameTiny"
        >
          Last Update:
        </div>
        <div
          key={"bottomDataEndpointLastUpdate_" + endpointName}
          className="dataEndpointLastUpdate dataItemValueTiny timestamp"
        >
          {parseTimestamp(endpointData.timestamps[0], "YYYY-MM-DD HH:mm:ss") ??
            "?"}
        </div>
        <div
          key={"bottomDataClear2_" + endpointName}
          className="dataItemClear"
        ></div>
      </div>
    );
  }

  renderBottomData(endpointName, attribute) {
    var value = attribute.values[0];
    var percentValue = attribute.valuesPercent
      ? attribute.valuesPercent[0] * 100.0
      : value;
    if (value && !isNaN(value)) value = parseFloat("" + value).toFixed(2);
    if (percentValue && !isNaN(percentValue))
      percentValue = parseFloat("" + percentValue).toFixed(2);
    let isTimestamp = attribute.valueClass.split(" ").includes("timestamp");
    let isPercent = attribute.unit === "%";
    return (
      <div
        key={"bottomData_" + endpointName + "_" + attribute.rawAttributeName}
        className="endpointDataItem"
      >
        <div
          key={
            "bottomDataName_" + endpointName + "_" + attribute.rawAttributeName
          }
          className={"dataItemNameTiny " + attribute.nameClass}
        >
          {attribute.name.tiny}
        </div>

        <div
          key={
            "bottomDataValue_" + endpointName + "_" + attribute.rawAttributeName
          }
          className={"dataItemValueTiny " + attribute.valueClass}
        >
          {isTimestamp
            ? parseTimestamp(value, "YYYY-MM-DD HH:mm:ss")
            : isPercent
            ? percentValue
            : value}
          {attribute.unit}
        </div>
      </div>
    );
  }

  renderBottom(endpoints) {
    return (
      <div className="endpointData endpointEndpoint">
        {endpoints.map((endpoint) => this.renderBottomEndpoint(endpoint))}
      </div>
    );
  }

  renderOffline(endpoints) {
    return (
      <div className="endpointData endpointEndpoint">
        {endpoints.map((endpoint) => (
          <div key={"bottomDataEndpointNameDiv_" + endpoint.endpointAddr}>
            <div
              key={"bottomDataEndpointName_" + endpoint.endpointAddr}
              className="dataEndpointName dataItemNameTiny"
            >
              {this.getName(endpoint)}:&nbsp;
            </div>
            <div
              key={"bottomDataEndpointLastUpdateName_" + endpoint.endpointAddr}
              className="dataEndpointLastUpdate dataItemNameTiny offlineEndpoint"
            >
              Offline
            </div>
            <div
              key={"bottomDataEndpointLastUpdate_" + endpoint.endpointAddr}
              className="dataEndpointLastUpdate dataItemValueTiny timestamp"
            >
              {parseTimestamp(endpoint.timestamps[0], "YYYY-MM-DD HH:mm:ss") ??
                "?"}
            </div>
            <div
              key={"bottomDataClear_" + endpoint.endpointAddr}
              className="dataItemClear"
            ></div>
          </div>
        ))}
      </div>
    );
  }

  renderEmpty() {
    return (
      <div className="endpointMiddle">
        <div className="dataItemValueNormalCenter">Empty</div>
      </div>
    );
  }

  renderCard() {
    var batt =
      this.props.endpoints
        .filter((endpoint) => endpoint)
        .reduce((arr, endpoint) => arr.concat(endpoint.capabilities), [])
        .filter(
          (attribute) =>
            attribute.values &&
            attribute.capabilityType.includes("AttributeBattery")
        )
        .reduce(
          (prev, curr) =>
            (prev ?? curr.values[0]) < curr.values[0] ? prev : curr.values[0],
          null
        ) ?? 0;
    var battPercent =
      this.props.endpoints
        .filter((endpoint) => endpoint)
        .reduce((arr, endpoint) => arr.concat(endpoint.capabilities), [])
        .filter(
          (attribute) =>
            attribute.values &&
            attribute.capabilityType.includes("AttributeBattery")
        )
        .reduce(
          (prev, curr) =>
            (prev ?? curr.valuesPercent[0]) < curr.valuesPercent[0]
              ? prev
              : curr.valuesPercent[0],
          null
        ) ?? 1.0;
    var lqi =
      this.props.endpoints
        .filter((endpoint) => endpoint)
        .reduce((arr, endpoint) => arr.concat(endpoint.capabilities), [])
        .filter(
          (attribute) =>
            attribute.values &&
            attribute.capabilityType.includes("AttributeLQI")
        )
        .reduce(
          (prev, curr) =>
            (prev ?? curr.values[0]) < curr.values[0] ? prev : curr.values[0],
          null
        ) ?? 0;
    var lqiPercent =
      this.props.endpoints
        .filter((endpoint) => endpoint)
        .reduce((arr, endpoint) => arr.concat(endpoint.capabilities), [])
        .filter(
          (attribute) =>
            attribute.values &&
            attribute.capabilityType.includes("AttributeLQI")
        )
        .reduce(
          (prev, curr) =>
            (prev ?? curr.valuesPercent[0]) < curr.valuesPercent[0]
              ? prev
              : curr.valuesPercent[0],
          null
        ) ?? 0;
    var online =
      this.props.endpoints
        .filter((endpoint) => endpoint)
        .reduce((prev, curr) => prev && curr.online, true) ?? false;
    if (!online) lqiPercent = 0;
    return (
      <div
        className={"cardAction" + " " + (online ? "online" : "offline")}
        onClick={() => {
          this.handleClick();
        }}
      >
        <div className="endpointData endpointCommon">
          <div className="topLeft">
            <div className="cardName">{this.props.contextCard.card.name}</div>
          </div>
          <div className="topRight">
            <div
              className={
                "dataItemValueTiny lqi lqiValueTiny " +
                ("lqiValueTiny_" +
                  parseInt(Math.ceil((lqiPercent * 100) / 20.0)))
              }
            >
              {lqi}
            </div>
            <div
              className={
                "dataItemValueTiny batt battValueTiny " +
                ("battValueTiny_" +
                  parseInt(Math.round((battPercent * 100) / 20.0)))
              }
            >
              {batt}
            </div>
            <div
              className={
                "cardButton cardEditButton " +
                (this.props.locked ? "hidden" : "")
              }
              onClick={(e) => {
                e.stopPropagation();
                this.props.handleDialogCardEditOpen();
              }}
            >
              &#x1f589;
            </div>
          </div>
          <div className="endpointIconSmall">
            {this.props.endpoints
              .filter((endpoint) => endpoint)
              .map((endpoint, index) => (
                <img
                  key={"icon_" + index}
                  src={
                    process.env.PUBLIC_URL +
                    "/endpointType_" +
                    endpoint.endpointTypeName +
                    ".png"
                  }
                  alt="endpoint icon"
                />
              ))}
          </div>
          <div className="dataItemClear"></div>
        </div>
        {this.renderMiddle(
          this.props.endpoints
            .filter((endpoint) => endpoint)
            .filter((endpoint) => endpoint.online)
        )}
        {this.renderBottom(
          this.props.endpoints
            .filter((endpoint) => endpoint)
            .filter((endpoint) => endpoint.online)
        )}
        {this.renderOffline(
          this.props.endpoints
            .filter((endpoint) => endpoint)
            .filter((endpoint) => !endpoint.online)
        )}
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.detailsMode) {
      let detailEndpoints = Object.values(this.props.endpoints).map(
        (endpoint) => endpoint.endpointAddr
      );
      if (
        JSON.stringify(this.state.detailsDataLoaded) !==
          JSON.stringify(detailEndpoints) ||
        this.props.detailsPeriod !== prevProps.detailsPeriod
      ) {
        this.setState({
          detailsDataLoaded: detailEndpoints,
        });
        this.props.handleLoadData(
          detailEndpoints,
          this.getMeta(),
          this.props.detailsPeriod
        );
      }
    }
  }

  getName(endpoint) {
    var name = endpoint.mapperName;
    if (!name) return endpoint.name;

    if (endpoint.location && endpoint.location.length)
      name = name + "(" + endpoint.location + ")";
    else name = name + "(" + endpoint.name + ")";
    return name;
  }

  prepareAttributesForChart(meta, endpointData) {
    let fields = meta.detailsConfig.dataAggregation
      .reduce((prev, curr) => prev.concat(curr), [])
      .map((field) => field.fieldByCapabilityType);
    var capabilities;
    var timestamps;
    if ("raw" === this.props.detailsPeriod) {
      capabilities = endpointData.capabilities;
      timestamps = endpointData.timestamps;
    } else {
      capabilities =
        endpointData["capabilities_" + this.props.detailsPeriod] ?? [];
      timestamps = endpointData["timestamps_" + this.props.detailsPeriod] ?? [];
    }

    var attributes = capabilities.filter((attribute) =>
      attribute.capabilityType.some((r) => fields.includes(r))
    );
    attributes = attributes.filter(
      (attribute) => !attribute.capabilityType.includes("AttributeLQI")
    );
    attributes = attributes.filter(
      (attribute) => !attribute.capabilityType.includes("AttributeBattery")
    );
    attributes = attributes.filter(
      (attribute) => !attribute.valueClass.split(" ").includes("timestamp")
    );
    attributes = attributes.filter(
      (attribute) => attribute.displayOnChart !== false
    );

    return [timestamps, attributes];
  }

  addLastDataToChartData(data, endpointData) {
    if (endpointData.online) {
      let lastData = data[data.length - 1] ?? { timestamp: null };
      data.push({
        ..._.mapKeys(lastData, (value, key) => "_current_" + key),
        timestamp: lastData.timestamp,
        attributes: _.mapKeys(
          lastData.attributes,
          (value, key) => "_current_" + key
        ),
      });
      data.push({
        ..._.mapKeys(lastData, (value, key) => "_current_" + key),
        timestamp: Math.floor(new Date().getTime() / 1000),
        attributes: _.mapKeys(
          lastData.attributes,
          (value, key) => "_current_" + key
        ),
      });
    } else {
      data.push({ timestamp: Math.floor(new Date().getTime() / 1000) });
    }
    return data;
  }

  mapYScale(scale) {
    if (scale === "d3log") return scaleSymlog().constant(100);
    return scale;
  }

  prepareAttributesDataForChart(timestamps, attributes) {
    return timestamps
      .map((timestamp, index) => {
        var dataObj = {
          timestamp: timestamp,
          attributes: _.keyBy(attributes, (o) => o.rawAttributeName),
        };
        for (let it in attributes) {
          let attribute = attributes[it];
          attribute._formatters = [
            (value, name, props) =>
              Number.isNaN(parseFloat(value))
                ? value
                : Number.isInteger(value)
                ? value
                : parseFloat(value) > 9
                ? parseFloat(value).toFixed(1)
                : parseFloat(value).toPrecision(2),
          ];

          let val = attribute.values[index];
          if (attribute.unit === "%") {
            val = attribute.valuesPercent[index] * 100.0;
            attribute.rangeMaxPercent = attribute.rangeMax;
            if (attribute.rangeMaxPercent <= 1)
              attribute.rangeMaxPercent *= 100.0;
          } else if (attribute.unit === "sec" || attribute.origUnit === "sec") {
            if (!attribute.origUnit) {
              attribute.origUnit = attribute.unit;
              attribute.unit = "";
            }
            attribute._formatters.push((value, name, props) => {
              if (Number.isNaN(parseFloat(value))) return value;
              value = autoScaleTime(parseFloat(value));
              return value;
            });
          }

          if (attribute) {
            dataObj[attribute.rawAttributeName] = val;
          }
        }
        return dataObj;
      })
      .reverse();
  }

  renderDetails() {
    return (
      <>
        {this.props.endpoints.map((endpointData) => {
          let meta = this.getMeta();
          var [timestamps, attributes] = this.prepareAttributesForChart(
            meta,
            endpointData
          );

          let data = this.prepareAttributesDataForChart(timestamps, attributes);

          data = this.addLastDataToChartData(data, endpointData);

          let timeRange = [timestamps[timestamps.length - 1], timestamps[0]];

          return (
            <div
              key={"chartDivContainer_" + endpointData.endpointAddr}
              width="100%"
              className="dialogContentStretch chart"
            >
              <div
                key={"chartDiv_" + endpointData.endpointAddr}
                className="fillAll"
              >
                <div key={"chartName_" + endpointData.endpointAddr}>
                  {this.getName(endpointData)}
                </div>
                <ResponsiveContainer
                  key={"chartContainer_" + endpointData.endpointAddr}
                  width="100%"
                  height="100%"
                  className="chart"
                >
                  <AreaChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 30,
                      bottom: 20,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />

                    <XAxis
                      dataKey="timestamp"
                      domain={timeRange}
                      type="number"
                      scale="time"
                      angle={0}
                      tickFormatter={(tick) =>
                        parseTimestamp(tick, "YYYY-MM-DD HH:mm:ss")
                      }
                    />

                    <Tooltip
                      labelFormatter={(label) =>
                        parseTimestamp(label, "YYYY-MM-DD HH:mm:ss")
                      }
                      formatter={(value, name, props) => {
                        if (!props.payload.attributes) return value;
                        let attribute = props.payload.attributes[props.dataKey];
                        if (attribute) {
                          for (let it in attribute._formatters) {
                            let formatter = attribute._formatters[it];
                            value = formatter(value, name, props);
                          }
                        }
                        return value;
                      }}
                      contentStyle={{
                        color: "var(--fontColor)",
                        backgroundColor: "var(--primaryBackground)",
                      }}
                    />
                    <Legend />
                    {attributes.map((attribute, index) =>
                      attribute.chartType === "ReferenceLine" ? (
                        <Fragment key={"fragment_" + index}>
                          <YAxis
                            key={"axis_" + index}
                            hide="true"
                            width={35}
                            domain={[
                              attribute.rangeMin ?? "auto",
                              attribute.rangeMaxPercent ??
                                attribute.rangeMax ??
                                "auto",
                            ]}
                            yAxisId={index}
                            axisLine={{ stroke: colors[index % colors.length] }}
                          />
                          <ReferenceLine
                            key={"line_" + index}
                            y={attribute.values[0]}
                            yAxisId={index}
                            isAnimationActive="false"
                            strokeDasharray="3 3"
                            stroke={colors[index % colors.length]}
                          />
                        </Fragment>
                      ) : (
                        <Fragment key={"fragment_" + index}>
                          <YAxis
                            key={"axis_" + index}
                            width={35}
                            hide="true"
                            domain={[
                              attribute.rangeMin ?? "auto",
                              attribute.rangeMaxPercent ??
                                attribute.rangeMax ??
                                "auto",
                            ]}
                            yAxisId={index}
                            axisLine={{ stroke: colors[index % colors.length] }}
                            scale={this.mapYScale(attribute.chartScale)}
                          />
                          <Area
                            key={"line_" + index}
                            yAxisId={index}
                            type={attribute.chartInterpolation ?? "linear"}
                            dataKey={attribute.rawAttributeName}
                            unit={attribute.unit}
                            name={attribute.label}
                            connectNulls="true"
                            isAnimationActive="false"
                            animationDuration="0"
                            strokeWidth={2}
                            stroke={colors[index % colors.length]}
                            fill={colors[index % colors.length]}
                            fillOpacity={0.1}
                            dot={false}
                          />
                          <Area
                            key={"line_current_" + index}
                            yAxisId={index}
                            type={attribute.chartInterpolation ?? "linear"}
                            dataKey={"_current_" + attribute.rawAttributeName}
                            legendType="none"
                            label={false}
                            unit={attribute.unit}
                            name={attribute.label}
                            connectNulls="true"
                            isAnimationActive="false"
                            animationDuration="0"
                            strokeWidth={2}
                            stroke={colors[index % colors.length]}
                            fill={colors[index % colors.length]}
                            fillOpacity={0.1}
                            dot={false}
                            strokeDasharray="2 2"
                          />
                        </Fragment>
                      )
                    )}
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </div>
          );
        })}
      </>
    );
  }

  render() {
    if (!this.props.detailsMode) return this.renderCard();
    else return this.renderDetails();
  }
}
