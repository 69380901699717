import React from "react";
import "@polymer/paper-button/paper-button.js";
import "@polymer/paper-dialog/paper-dialog.js";
import "@polymer/paper-dialog-scrollable/paper-dialog-scrollable.js";
import _, { first } from "lodash";
import { Tabs, Tab } from "./components/tabs.js";
import { InplaceEdit } from "./components/inplaceEdit";
import { now } from "moment";
import { ProgressCircle } from "./components/progressCircle";

const PAIRING_TIMEOUT = 60;

export default class AddDeviceDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "select",
      mapperType: "",
      mapperName: "name",
      mapperFields: {},
      pairingTimeStarted: null,
      pairingTimeLeft: 0,
      endpointCountStart: Object.values(this.props.allEndpoints).length,
    };
    this.addDeviceDialog = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.open !== nextProps.open) {
      var firstMapperType = "";
      if (this.props.inputMappers && this.props.inputMappers[0]) {
        firstMapperType = this.props.inputMappers[0].id;
      }

      if (nextProps.open) {
        this.addDeviceDialog.current.open();
        this.setState({
          mode: "select",
          mapperType: firstMapperType,
          mapperName: "name",
          mapperFields: {},
        });
      } else this.addDeviceDialog.current.close();
    }
    if (this.state.mode !== nextState.mode) {
      if (nextState.mode === "automatic") {
        this.startDevicePairing();
      }
    }
    return true;
  }

  componentDidMount() {
    this.addDeviceDialog.current.addEventListener("iron-overlay-closed", () => {
      this.props.onClose();
    });
  }

  startDevicePairing() {
    let timeoutValue = PAIRING_TIMEOUT;
    fetch(window.serverAddr + "/api/request/permit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": window.token,
      },
      body: JSON.stringify({
        timeout: timeoutValue,
        execute: true,
      }),
      mode: "cors",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("startDevicePairing", result);
        this.setState({
          pairingTimeLeft: timeoutValue,
          pairingTimeStarted: now(),
          endpointCountStart: Object.values(this.props.allEndpoints).length,
        });
        setTimeout(() => this.updatePairingTimeLeft(), 1000);
      });
  }

  updatePairingTimeLeft() {
    let left = this.state.pairingTimeLeft - 1;
    if (left < 0) return;

    this.setState({
      pairingTimeLeft: left,
    });
    setTimeout(() => this.updatePairingTimeLeft(), 1000);
  }

  handleSelectMapperType(value) {
    this.setState({
      mapperType: value,
      mapperFields: {},
    });
  }

  handleEditMapperField(key, value) {
    let newMapperFields = { ...this.state.mapperFields, [key]: value };
    this.setState({
      mapperFields: newMapperFields,
    });
  }

  handleEditMapperName(value) {
    this.setState({
      mapperName: value,
    });
  }

  getEndpointName(endpoint) {
    var name = endpoint.mapperName;
    if (!name) return endpoint.name;

    if (endpoint.location && endpoint.location.length)
      name = name + "(" + endpoint.location + ")";
    else name = name + "(" + endpoint.name + ")";
    return name;
  }

  filterEndpoints(attributes) {
    return Object.keys(this.props.allEndpoints)
      .filter((endpointKey) => this.props.allEndpoints[endpointKey] !== null)
      .filter((endpointKey) =>
        attributes.every((v) =>
          this.props.allEndpoints[endpointKey].capabilityTypes.includes(v)
        )
      )
      .map((endpointKey) => {
        return {
          key: this.props.allEndpoints[endpointKey].endpointAddr,
          value: this.getEndpointName(this.props.allEndpoints[endpointKey]),
        };
      });
  }

  handleAddManual() {
    let mappers = this.props.inputMappers.filter(
      (mapper) => mapper.manufacturer === "_virt_"
    );
    let selectedMapper = mappers.filter(
      (mapper) => mapper.id === this.state.mapperType
    )[0];
    if (!selectedMapper) return;

    fetch(window.serverAddr + "/api/request/addVirtual", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": window.token,
      },
      body: JSON.stringify({
        model: selectedMapper.model,
        manufacturer: selectedMapper.manufacturer,
        data: this.state.mapperFields,
        execute: true,
      }),
      mode: "cors",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("addVirtual", result);
        this.props.handleClose();
      });
  }

  render() {
    let mappers = this.props.inputMappers.filter(
      (mapper) => mapper.manufacturer === "_virt_"
    );
    let selectedMapper = mappers.filter(
      (mapper) => mapper.id === this.state.mapperType
    )[0];

    return (
      <paper-dialog id="dialogCardDetails" ref={this.addDeviceDialog}>
        <div className="dialogContent80">
          <Tabs
            items={[
              {
                label: "Select",
                state: "select",
                hidden: true,
                noselect: this.state.mode !== "select",
                defaultTab: true,
              },
              {
                label: "Automatic",
                noselect: this.state.mode !== "select",
                state: "automatic",
              },
              {
                label: "Manual",
                noselect: this.state.mode !== "select",
                state: "manual",
              },
            ]}
            setState={(state) => {
              this.setState({ mode: state });
            }}
            visibleTab={this.state.mode}
          />

          <div className="dialogContentStretch">
            <Tab title="Select" visible={this.state.mode === "select"}>
              <div>
                <paper-button
                  raised
                  class="button"
                  onClick={() => {
                    this.setState({ mode: "automatic" });
                  }}
                >
                  Automatic
                </paper-button>
              </div>
              <div>
                <paper-button
                  raised
                  class="button"
                  onClick={() => {
                    this.setState({ mode: "manual" });
                  }}
                >
                  Manual
                </paper-button>
              </div>
            </Tab>
            <Tab title="Automatic" visible={this.state.mode === "automatic"}>
              {this.state.pairingTimeLeft > 0 ? (
                <>
                  <ProgressCircle
                    displayValue={"" + this.state.pairingTimeLeft + " sec"}
                    valuePercent={
                      100 *
                      ((PAIRING_TIMEOUT - this.state.pairingTimeLeft) /
                        PAIRING_TIMEOUT)
                    }
                  />
                  <p>Looking for endpoints</p>
                  <p>
                    Found{" "}
                    {Object.values(this.props.allEndpoints).length -
                      this.state.endpointCountStart}
                  </p>
                </>
              ) : (
                <div>
                  <paper-button
                    raised
                    class="button"
                    onClick={() => {
                      this.startDevicePairing();
                    }}
                  >
                    Start
                  </paper-button>
                </div>
              )}
            </Tab>
            <Tab title="Manual" visible={this.state.mode === "manual"}>
              <div className="mapperSelect">
                <select
                  value={this.state.mapperType}
                  onChange={(event) =>
                    this.handleSelectMapperType(event.target.value)
                  }
                >
                  {mappers.map((mapper) => (
                    <option key={"mapper_" + mapper.id} value={mapper.id}>
                      {mapper.manufacturer}:{mapper.model}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mapperName">
                <input
                  type="text"
                  required="required"
                  value={this.state.mapperName}
                  placeholder="name"
                  onChange={(event) =>
                    this.handleEditMapperName(event.target.value)
                  }
                />
              </div>
              <div className="mapperFields">
                {selectedMapper ? (
                  Object.keys(selectedMapper.meta.deviceDataFieldsMeta).map(
                    (fieldKey) => {
                      let field =
                        selectedMapper.meta.deviceDataFieldsMeta[fieldKey];
                      return (
                        <div key={"mapperField_" + fieldKey}>
                          <div className="mapperFieldName">{fieldKey}</div>
                          <div className="mapperFieldEdit">
                            <InplaceEdit
                              className="editable"
                              editable={true}
                              secret={field.secret}
                              multiLine={field.type === "text"}
                              required={field.required}
                              value={this.state.mapperFields[fieldKey] ?? ""}
                              placeholder={
                                field.type +
                                (field.suggestions.length
                                  ? ": " + JSON.stringify()
                                  : "")
                              }
                              options={
                                field.type === "endpoint"
                                  ? this.filterEndpoints(field.filter ?? [])
                                  : field.suggestions
                              }
                              strict={field.type === "endpoint"}
                              onChange={(value, invalid) =>
                                this.handleEditMapperField(fieldKey, value)
                              }
                            />
                          </div>
                        </div>
                      );
                    }
                  )
                ) : (
                  <div></div>
                )}
                <div className="mapperFieldsAddButton">
                  <paper-button
                    raised
                    class="button"
                    onClick={() => {
                      this.handleAddManual();
                    }}
                  >
                    Add
                  </paper-button>
                </div>
              </div>
            </Tab>
          </div>
          <div className="dialogContentButtons">
            <paper-button
              raised
              class="button"
              onClick={() => {
                this.props.handleClose();
              }}
            >
              Close
            </paper-button>
          </div>
        </div>
      </paper-dialog>
    );
  }
}
