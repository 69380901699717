import moment from "moment";
export function parseTimestamp(utcTimestamp, format) {
  var utc = moment.utc(parseFloat(utcTimestamp) * 1000);
  return moment(utc).local().format(format);
}

export function unixTimestamp(str, format) {
  return moment(str, format).unix();
}

export function autoScaleTime(time) {
  if (Math.abs(time) < 120) return "" + parseFloat(time) + " sec";
  if (Math.abs(time) < 5400) return "" + Math.round(time / 60) + " min";
  if (Math.abs(time) < 3600 * 24) return "" + (time / 3600.0).toFixed(1) + " h";

  return "" + Math.round(time / (3600 * 24)) + " days";
}
