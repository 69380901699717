import React from "react";
import "./tabs.css";
import "@polymer/paper-button/paper-button.js";

/* props:
    - items {
        - label
        - state
        - align? ["left","right"]
        - customHandler(state)?
        - hideonoverlow?
        - hidden?
        - noselect?
    }
    - setState(state)
*/

export class Tabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMore: false,
    };
  }

  render() {
    let children = this.props.children ?? [];
    if (!Array.isArray(children)) {
      children = [children];
    }

    return (
      <div className="tabs">
        <div className="tabsWrapper">
          <ul>
            <li
              className="tabsShortMode alignRight"
              onClick={() => this.setState({ showMore: !this.state.showMore })}
            >
              <div>...</div>
              <div
                className={
                  "displayMore " + (this.state.showMore ? "visible" : "hidden")
                }
              >
                <ul>
                  {this.props.items
                    .filter((item) => item.hideonoverlow)
                    .map((item) => (
                      <li
                        key={item.state}
                        onClick={() =>
                          item.customHandler
                            ? item.customHandler(item.state)
                            : this.props.setState(item.state)
                        }
                        className={
                          "" +
                          (this.props.visibleTab === item.state
                            ? "tabSelected"
                            : "") +
                          " " +
                          (item.hidden ? "hidden" : "")
                        }
                      >
                        {item.label}
                      </li>
                    ))}
                  {!children.filter((item) => item.props.hideonoverlow) ? (
                    <div></div>
                  ) : (
                    children
                      .filter((item) => item.props.hideonoverlow)
                      .map((item, index) => (
                        <li key={"children_" + index}>{item}</li>
                      ))
                  )}
                </ul>
              </div>
            </li>
            {this.props.items.map((item) => (
              <li
                key={item.state}
                onClick={() =>
                  item.customHandler
                    ? item.customHandler(item.state)
                    : this.props.setState(item.state)
                }
                className={
                  "" +
                  (this.props.visibleTab === item.state ? "tabSelected" : "") +
                  " " +
                  (item.align === "right" ? "alignRight" : "alignLeft") +
                  " " +
                  (item.hideonoverlow ? "tabsLongMode" : "") +
                  " " +
                  (item.hidden ? "hidden" : "") +
                  " " +
                  (item.noselect ? "noselect" : "")
                }
              >
                {item.label}
              </li>
            ))}
            {children.map((item, index) => (
              <li
                key={"children_" + index}
                className={
                  "" +
                  (item.props.align === "right" ? "alignRight" : "alignLeft") +
                  " " +
                  (item.props.hideonoverlow ? "tabsLongMode" : "") +
                  " " +
                  (item.props.hidden ? "hidden" : "") +
                  " " +
                  (item.props.noselect ? "noselect" : "")
                }
              >
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

/* props:
    - visible
    - children
*/

export class Tab extends React.Component {
  render() {
    return (
      <div className={this.props.visible ? "tab tabVisible" : "tab tabHidden"}>
        <div className="tabWrapper">{this.props.children}</div>
      </div>
    );
  }
}
