export const importsEndpoints = {
  //do not add Card: card.js,
  Endpoint: { module: "endpoint.js", description: "All devices" },
  //"EndpointDevice": {module: "endpointDevice.js", visible: false},
  EndpointDebug: {
    module: "endpointDebug.js",
    description: "All devices + debug",
  },
  EndpointDebugRaw: {
    module: "endpointDebugRaw.js",
    description: "All devices + debug + raw",
  },
  EndpointTemp: {
    module: "endpointTemp.js",
    description: "Temperature sensors",
  },
  EndpointTempOneChart: {
    module: "endpointTempOneChart.js",
    description: "Temperature sensors (one chart)",
  },
  EndpointLight: { module: "endpointLight.js", description: "Lights" },
  EndpointPlug: { module: "endpointPlug.js", description: "Plugs" },
  EndpointOpenClose: {
    module: "endpointOpenClose.js",
    description: "Switch, door, window sensors",
  },
  EndpointOccupancy: {
    module: "endpointOccupancy.js",
    description: "Occupancy detectors",
  },
  EndpointAirQuality: {
    module: "endpointAirQuality.js",
    description: "Air quality sensors",
  },
  EndpointLeak: { module: "endpointLeak.js", description: "Leak detectors" },
  EndpointTimer: {
    module: "endpointTimer.js",
    description: "Timers",
  },
};
